// import { pick, isInteger, pickBy, isNil, keys } from 'lodash';
import * as actions from '../../store/actions';
import api from './Request';
import { getRequestStatusActions } from '../../utils/createRequestActions';

export const getPublicProfileList = () =>
  // TODO remove _disablePagination
  api.get(
    'public/profile',
    { params: { _disableCache: 1, _disablePagination: 1 } },
    getRequestStatusActions(actions.requestPublicProfileList)
  );
