import { useEffect, useState } from 'react';
import * as React from 'react';
import { Placeslist } from './types';
import { FloorWrapper } from './components/FloorWrapper';
import {
  PARK_KULTURY_4_AREAS,
  PARK_KULTURY_5_AREAS,
  KOMSOMOLSKAYA_FLOOR_AREAS,
  KOMSOMOLSKAYA_FOK_FLOOR_0_AREAS,
  KOMSOMOLSKAYA_FOK_FLOOR_1_AREAS,
  KOMSOMOLSKAYA_FOK_FLOOR_2_AREAS,
  NEO_GEO_FLOOR_1_A_AREAS,
  NEO_GEO_FLOOR_1_C_AREAS,
  TULSKAYA_FLOOR_6_AREAS,
  TULSKAYA_FLOOR_5_AREAS
} from './areas';
import { LazyFloorPlan } from './components/LazyFloorPlan';
import { FloorAreasConstructor } from './components/FloorAreasConstructor';
import Draggable from 'react-draggable';
import { getFloorPlaces } from './utils/getFloorPlaces';
import styled from 'styled-components';
import { colors } from '../../../shared/ui/constants/styleConstants';
import { ScalingIcon } from '../../../shared/ui/icons/core/ScalingIcon';
import { NEO_GEO_FLOOR_2_A_AREAS } from './areas/NeoGeoAreas';
import { PARK_KULTURY_6_AREAS } from './areas/ParkKulturyAreas';

const WorkplaceFloorBox = styled.div`
  :hover {
    cursor: grab;
  }
  background: repeating-linear-gradient(-60deg, #cfd1d2 0, #cfd1d2 1px, transparent 1px, transparent 5px);
  display: flex;
  flex-direction: column;
  height: ${props => props.containerSize}px;
  padding: 30px;
  overflow-x: scroll;

  @media (max-width: 970px) {
    flex-direction: column-reverse;
    margin: 0 -60px;
  }

  @media (max-width: 744px) {
    flex-direction: column-reverse;
    margin: 0 -20px;
  }

  ::-webkit-scrollbar {
    width: 3px;
    border-radius: 5px;
  }
`;
const WorkplaceControlButtonsArea = styled.section`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  height: 100vh;
  justify-content: center;
  z-index: 9;
`;

const WorkplaceScalingButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
`;

const WorkplaceScalingButton = styled.button`
  padding: 12px 8px;
  width: 40px;
  height: 84px;
  margin: 12px;
  border-radius: 50px;
  background-color: ${colors.workkiDarkFill};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  :hover: ${colors.workkiDarkMain};
  :first-child {
    margin-bottom: 12px;
  }
`;

export const FloorPlan = ({ selectedPlace, workplaces, openPriceCard, officeId, filtersActive }) => {
  const [floorAreas, setFloorAreas] = useState();
  const [constructorSize, setConstructorSize] = useState();
  const [scaleFactor, setScaleFactor] = useState(1);
  const [containerSize, setContainerSize] = useState(600);
  const nodeRef = React.useRef(null);
  useEffect(() => {
    if (selectedPlace.includes('park_kultury') && filtersActive) {
      setScaleFactor(1.1);
    } else if (selectedPlace.includes('tulskaya') && filtersActive) {
      setScaleFactor(1.3);
    } else if (filtersActive) {
      setScaleFactor(0.9);
    }
  }, [filtersActive, selectedPlace]);

  useEffect(() => {
    switch (selectedPlace) {
      case Placeslist.KOMSOMOLSKAYA:
        setFloorAreas(KOMSOMOLSKAYA_FLOOR_AREAS);
        break;
      case Placeslist.KOMSOMOLSKAYA_FOK_0:
        setFloorAreas(KOMSOMOLSKAYA_FOK_FLOOR_0_AREAS);
        setConstructorSize({ width: 1220, height: 740 });
        setContainerSize(600);
        break;
      case Placeslist.KOMSOMOLSKAYA_FOK_1:
        setFloorAreas(KOMSOMOLSKAYA_FOK_FLOOR_1_AREAS);
        setConstructorSize({ width: 1220, height: 740 });
        setContainerSize(600);
        break;
      case Placeslist.KOMSOMOLSKAYA_FOK_2:
        setFloorAreas(KOMSOMOLSKAYA_FOK_FLOOR_2_AREAS);
        setConstructorSize({ width: 1220, height: 740 });
        setContainerSize(600);
        break;
      case Placeslist.PARK_KULTURY_4:
        setFloorAreas(PARK_KULTURY_4_AREAS);
        setConstructorSize({ width: 2880 });
        setContainerSize(600);
        setScaleFactor(1.4);
        break;
      case Placeslist.PARK_KULTURY_5:
        setFloorAreas(PARK_KULTURY_5_AREAS);
        setConstructorSize({ width: 2880 });
        setContainerSize(600);
        setScaleFactor(1.4);
        break;
      case Placeslist.PARK_KULTURY_6:
        setFloorAreas(PARK_KULTURY_6_AREAS);
        setConstructorSize({ width: 2880 });
        setContainerSize(600);
        setScaleFactor(1.4);
        break;
      case Placeslist.NEO_GEO_1_A:
        setFloorAreas(NEO_GEO_FLOOR_1_A_AREAS);
        setConstructorSize({ width: 1570, height: 1270 });
        setContainerSize(800);
        break;
      case Placeslist.NEO_GEO_1_C:
        setFloorAreas(NEO_GEO_FLOOR_1_C_AREAS);
        setConstructorSize({ width: 1950, height: 850 });
        setContainerSize(800);
        break;
      case Placeslist.NEO_GEO_1_A:
        setFloorAreas(NEO_GEO_FLOOR_1_A_AREAS);
        setConstructorSize({ width: 1570, height: 1270 });
        setContainerSize(800);
        break;
      case Placeslist.NEO_GEO_2_A:
        setFloorAreas(NEO_GEO_FLOOR_2_A_AREAS);
        setConstructorSize({ width: 1950, height: 850 });
        setContainerSize(800);
        break;
      case Placeslist.TULSKAYA_6:
        setFloorAreas(TULSKAYA_FLOOR_6_AREAS);
        setConstructorSize({ width: 3660, height: 1060 });
        setContainerSize(800);
        setScaleFactor(1.6);
        break;
      case Placeslist.TULSKAYA_5:
        setFloorAreas(TULSKAYA_FLOOR_5_AREAS);
        setConstructorSize({ width: 3660, height: 1060 });
        setContainerSize(800);
        setScaleFactor(1.6);
        break;
    }
  }, [selectedPlace]);

  const increaseScale = () => {
    setScaleFactor(scaleFactor + 0.2);
  };

  const decreaseScale = () => {
    if (scaleFactor > 0) {
      setScaleFactor(scaleFactor - 0.2);
    }
  };

  const [touchInfo, setTouchInfo] = useState({
    startX: null,
    startY: null,
    endX: null,
    endY: null
  });
  const [deltaValue, setDeltaValue] = useState({ deltaX: 1, deltaY: 1 });

  const handleTouchStart = e => {
    const touch = e.touches[0];
    setTouchInfo({
      startX: touch.clientX,
      startY: touch.clientY,
      endX: touchInfo.endX,
      endY: touchInfo.endY
    });
  };

  const handleTouchEnd = e => {
    if (touchInfo.startX !== null && touchInfo.startY !== null) {
      const touch = e.changedTouches[0];
      setTouchInfo({
        startX: touchInfo.startX,
        startY: touchInfo.startY,
        endX: touch.clientX,
        endY: touch.clientY
      });

      const deltaX = touch.clientX - touchInfo.startX;
      const deltaY = touch.clientY - touchInfo.startY;
      setDeltaValue({ deltaX: deltaX, deltaY: deltaY });
    }
  };

  if (floorAreas)
    return (
      <div>
        <WorkplaceControlButtonsArea>
          <WorkplaceScalingButtonsWrapper>
            <WorkplaceScalingButton>
              <ScalingIcon type={'increase'} spacing={'0'} handleAction={increaseScale} />
              <ScalingIcon type={'decrease'} spacing={'0'} handleAction={decreaseScale} />
            </WorkplaceScalingButton>
          </WorkplaceScalingButtonsWrapper>
        </WorkplaceControlButtonsArea>
        <WorkplaceFloorBox containerSize={containerSize}>
          <Draggable nodeRef={nodeRef} cancel='.no-cursor'>
            <div ref={nodeRef}>
              <FloorWrapper floorAreas={floorAreas} scaleFactor={scaleFactor} activeLocation={selectedPlace}>
                {constructorSize && (
                  <FloorAreasConstructor
                    filtersActive={filtersActive}
                    place={selectedPlace}
                    size={{ width: constructorSize.width, height: constructorSize.height }}
                    openPriceCard={openPriceCard}
                    floorAreas={getFloorPlaces(floorAreas, workplaces, officeId)}
                    className='.no-cursor'
                    onTouchStart={handleTouchStart}
                    onTouchEnd={handleTouchEnd}
                    deltaValue={deltaValue}
                  />
                )}
                <LazyFloorPlan
                  setSize={setConstructorSize}
                  className='workplace-floor-area-wrapper'
                  size={constructorSize ? constructorSize : { width: 2880, height: 850 }}
                  place={selectedPlace}
                />
              </FloorWrapper>
            </div>
          </Draggable>
        </WorkplaceFloorBox>
      </div>
    );
  else return null;
};

export default FloorPlan;
