import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { Moment } from '../../utils/moment';

const PrintHistoryTableBody = props => {
  const { rows } = props;

  return (
    <tbody className='print__history-table-body'>
      {rows.map(item => {
        const { created_at, filename } = item;

        return (
          <tr key={uniqueId()} className='print__history-table-row'>
            <td className='print__history-table-cell'>{filename}</td>
            <td className='print__history-table-cell'>
              <Moment locale='ru' parse='YYYY-MM-DD HH:mm' format='DD.MM.YYYY HH:mm'>
                {created_at}
              </Moment>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

PrintHistoryTableBody.propTypes = {
  rows: PropTypes.array.isRequired
};

export default PrintHistoryTableBody;
