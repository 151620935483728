import { connect } from 'react-redux';
import { error, success, info } from 'react-toastify-redux';
import { isArray } from 'lodash';
import { sendFileToPrintDirectly } from '../../services/workki/CustomerActions';
import ErrorWithShowedMessage from '../../errors/ErrorWithShowedMessage';

const mapDispatchToProps = dispatch => ({
  sendToFile: async file => {
    try {
      await dispatch(sendFileToPrintDirectly(file));
      dispatch(success('Файл готов к печати', { hideProgressBar: true }));
    } catch (err) {
      if (err instanceof ErrorWithShowedMessage) {
        throw err;
      }

      let message = 'Ошибка печати';

      if (err && err.response && err.response.data && isArray(err.response.data)) {
        message = err.response.data[0].message;
      }

      dispatch(error(message, { hideProgressBar: true }));
      throw new Error(message);
    }
  },
  showInfoToast: msg => dispatch(info(msg, { hideProgressBar: true }))
});

export default Component =>
  connect(
    null,
    mapDispatchToProps
  )(Component);
