import React from 'react';
import PrimaryLayout from '../../../layouts/PrimaryLayout';
import PageLoader from '../../../components/PageLoader';
import Payments from './Payments';

const PaymentsPage = props => (
  <PrimaryLayout>
    <main className='main'>{props.isLoading ? <PageLoader /> : <Payments />}</main>
  </PrimaryLayout>
);

export default PaymentsPage;
