import React from 'react';

const NumberSpinner = ({ minNumber, number, setNumber }) => {
  const incrNumber = evt => {
    evt.preventDefault();
    setNumber(number + 1);
  };

  const decrNumber = evt => {
    evt.preventDefault();
    if (number > minNumber) {
      setNumber(number - 1);
    }
  };

  return (
    <div className='number-spinner'>
      <button className='number-spinner__btn' onClick={decrNumber} type='button' name='decrNumBtn'>
        -
      </button>
      <input className='number-spinner__input' type='text' readOnly value={number} />
      <button className='number-spinner__btn' onClick={incrNumber} type='button' name='incrNumBtn'>
        +
      </button>
    </div>
  );
};

export default NumberSpinner;
