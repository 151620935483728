import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { officeSelector } from '../../store/selectors/DefaultSelectors';
import { serviceRequestWithWorkplacesSelector } from '../../store/selectors/ServicesSelector';
import { find, pick } from 'lodash';
import { getActiveServiceRequests, getItServiceTaskList } from '../../services/workki/ServicesActions';
import dayjs from 'dayjs';
import { ServicesDialog } from '../../widgets/Services/ServicesDialog';
import { GuestPassSuccessDialog } from '../../widgets/Services/GuestPass/GuestPassSuccessDialog';
import { StepButton } from '../../shared/ui/components/StepButton';
import { GroupTitle } from '../Payments/model/Payments';

const getWorkplaces = (activeWorkplaces, offices) => {
  return activeWorkplaces
    .map(workplace => {
      if (!workplace.reservation_place) {
        return null;
      }
      const place = pick(workplace.reservation_place, ['id', 'name', 'is_openspace']);
      const office = find(offices, o => o.id === workplace.reservation_place.office_id);

      return {
        ...place,
        officeName: office.t_name,
        office_id: office.id,
        bookingStartDate: workplace.start_at,
        bookingExpireDate: workplace.expire_at
      };
    })
    .filter(v => !!v);
};
const GuestsPanel = props => {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState({ guestPass: false, success: false });
  const offices = useSelector(officeSelector);
  useEffect(() => {
    if (props.outerModalOpen) {
      setOpenDialog(prevState => ({ ...prevState, guestPass: true }));
    }
  }, [props.outerModalOpen]);
  const activeWorkplaces = useSelector(state =>
    serviceRequestWithWorkplacesSelector(state, {
      dateCurrentSubscriptionView: dayjs().subtract(14, 'days'),
      selectorType: 'extended'
    })
  );
  const workplaces = useMemo(() => getWorkplaces(activeWorkplaces, offices), [activeWorkplaces, offices]);

  useEffect(() => {
    props.customer && dispatch(getItServiceTaskList());
    props.customer && dispatch(getActiveServiceRequests());
  }, [dispatch, props.customer]);
  useEffect(() => {
    if (props.guestCreateRequestStatus && props.guestCreateRequestStatus.isSuccess) {
      setOpenDialog(prevState => ({ ...prevState, success: true }));
    }
  }, [props.guestCreateRequestStatus]);

  return (
    <>
      {openDialog.guestPass && (
        <ServicesDialog
          title='Гостевой пропуск'
          serviceProps={props}
          workplaces={workplaces}
          dialogType='guestPass'
          handleSubmit={() => {
            setOpenDialog(prevState => ({ ...prevState, guestPass: false }));
            props.setOuterModalOpen(false);
          }}
          handleClose={() => {
            setOpenDialog(prevState => ({ ...prevState, guestPass: false }));
            props.setOuterModalOpen(false, 'guests');
          }}
        />
      )}
      {openDialog.success && (
        <GuestPassSuccessDialog
          dialogType='guestPassSuccess'
          title='Заявка отправлена'
          handleClose={() => {
            setOpenDialog(prevState => ({ ...prevState, success: false }));
          }}
        />
      )}
    </>
  );
};

GuestsPanel.propTypes = {
  setOpenModal: PropTypes.func.isRequired,
  guestsRequestStatus: PropTypes.object.isRequired
};

export default GuestsPanel;
