import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import CustomSwitch from '../Switch';
import { StepButton } from '../StepButton';
import { colors } from '../../constants/styleConstants';
import { PhoneIcon } from '../../icons/core/PhoneIcon';
import { MailIcon } from '../../icons/core/MailIcon';

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  'border': 'none',
  'width': '100%',
  'boxShadow': '0 2px 10px #0000001F',
  'borderRadius': '12px',
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2)
  },
  '&:before': {
    height: '0'
  }
}));
const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: '0 16px 16px 16px'
}));

const NameStatusWrapper = styled('div')({
  'display': 'flex',
  'flexDirection': 'row',
  'alignItems': 'center',
  'width': '100%',
  'justifyContent': 'space-between',
  '& > p': {
    margin: '0 16px 0 0',
    fontSize: '16px',
    fontWeight: '500'
  }
});

const SummaryContent = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

const ActionsContainer = styled('div')({
  '& > p': {
    margin: '0',
    color: `${colors.workkiDarkMiddle}`,
    fontWeight: '500'
  },
  'display': 'flex',
  'alignItems': 'center',
  'cursor': 'pointer'
});

const ContactInfo = styled('div')({
  'marginBottom': '8px',
  '& > p': {
    color: colors.workkiDarkFill,
    margin: 0,
    fontWeight: 300
  }
});
const ContactLinkWrapper = styled('div')({
  'display': 'flex',
  'alignItems': 'center',
  '& > a': {
    fontWeight: 400,
    fontSize: '14px',
    textDecoration: 'none',
    color: colors.workkiDarkFill
  }
});
const Actions = styled('div')({
  'display': 'flex',
  'marginTop': '8px',
  'width': '100%',
  'justifyContent': 'center',
  '& > button': {
    width: '50%'
  }
});

export const CustomAccordion = ({ employees, handleDelete, editEmployee, setEmployeeId }) => {
  const [expanded, setExpanded] = useState([]);
  const [checked, setChecked] = useState(true);

  const handleToggle = index => {
    setExpanded(prevExpanded => prevExpanded.map((isOpen, i) => (i === index ? !isOpen : isOpen)));
  };

  //TODO доработаю когда появятся статусы у сотрудников - на данный момент все добавленные сотрудники считаются активными
  const handleSwitchChange = () => {
    return null;
  };
  useState(() => {
    setExpanded(employees.map(() => false));
  }, [employees]);

  return (
    <>
      {employees
        .filter(employee => employee.is_active !== false)
        .map((employee, index) => (
          <StyledAccordion key={index} expanded={expanded[index]} onChange={() => handleToggle(index)}>
            <AccordionSummary expandIcon={null}>
              <SummaryContent>
                <NameStatusWrapper>
                  <p>{employee.name}</p>
                  <CustomSwitch
                    size={'small'}
                    spacing={'6px'}
                    checked={employee.is_active}
                    onChange={handleSwitchChange}
                  />
                </NameStatusWrapper>

                <ActionsContainer>
                  <p>Контакты и действия</p>
                  <ExpandMoreIcon
                    style={{
                      color: colors.workkiDarkMiddle,
                      transform: expanded[index] ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.2s'
                    }}
                  />
                </ActionsContainer>
              </SummaryContent>
            </AccordionSummary>
            <StyledAccordionDetails>
              <ContactInfo>
                <ContactLinkWrapper>
                  <PhoneIcon spacing='4px' defaultColor={colors.workkiDarkFill} />
                  <a href={`tel:${'+7' + employee.mobile_phone}`}>+7{employee.mobile_phone}</a>
                </ContactLinkWrapper>
                <ContactLinkWrapper>
                  <MailIcon spacing='4px' defaultColor={colors.workkiDarkFill} />
                  <a href={`mailto:${employee.email}`}>{employee.email}</a>
                </ContactLinkWrapper>
                <p>{employee.description}</p>
              </ContactInfo>
              <Actions>
                <StepButton
                  outlinedType={'red'}
                  type={'outlined'}
                  title={'Удалить'}
                  handleAction={() => handleDelete(employee.id)}
                />
                <StepButton
                  type={'outlined'}
                  title={'Редактировать'}
                  handleAction={() => {
                    setEmployeeId(employee.id);
                    editEmployee();
                  }}
                />
              </Actions>
            </StyledAccordionDetails>
          </StyledAccordion>
        ))}
    </>
  );
};
