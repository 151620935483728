import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { uniqueId } from 'lodash';

const alphabeticals = [
  ['0 — 9'],
  ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'k', 'l', 'm', 'n', 'o', 'p', 'r', 's', 't', 'u', 'w', 'x', 'y', 'z'],
  [
    'а',
    'б',
    'в',
    'г',
    'д',
    'е',
    'ж',
    'з',
    'и',
    'к',
    'л',
    'м',
    'н',
    'о',
    'п',
    'р',
    'с',
    'т',
    'у',
    'ф',
    'х',
    'ц',
    'ч',
    'ш',
    'щ',
    'э',
    'ю',
    'я'
  ]
];

const isActiveLetter = (chosenLetter, letter) => {
  return chosenLetter === letter;
};

const CommunityNavigation = props => {
  const { setLetter, activeLetter, setQuery, activeQuery } = props;

  const onBtnClick = evt => {
    evt.preventDefault();
    const currentLetter = evt.currentTarget.getAttribute('name');

    if (currentLetter === activeLetter) {
      return setLetter(null);
    }

    setLetter(currentLetter);

    if (activeQuery !== null) {
      setQuery(null);
    }
  };

  return (
    <div className='community__navigation'>
      {alphabeticals.map(item => {
        return (
          <ul className='community__navigation-list' key={uniqueId()}>
            {item.map(letter => {
              const name = letter === '0 — 9' ? '0-9' : letter;

              const btnClasses = cn({
                'community__navigation-btn': true,
                'btn': true,
                'active': isActiveLetter(activeLetter, name)
              });

              return (
                <li className='community__navigation-item' key={letter}>
                  <button onClick={onBtnClick} className={btnClasses} type='button' name={name}>
                    {letter}
                  </button>
                </li>
              );
            })}
          </ul>
        );
      })}
    </div>
  );
};

CommunityNavigation.propTypes = {
  setLetter: PropTypes.func.isRequired,
  activeLetter: PropTypes.string,
  activeQuery: PropTypes.string,
  setQuery: PropTypes.func.isRequired
};

export default CommunityNavigation;
