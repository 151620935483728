import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ItServicesTasks from './ItServicesTasks';
import ItServicesModal from './ItServicesModal';
import useModalState from '../../utils/useModalState';
import { getItServiceTaskList, getActiveServiceRequests } from '../../services/workki/ServicesActions';

const ItServices = props => {
  const dispatch = useDispatch();

  // MODAL STATE
  const [isModalWindowOpen, openModalWindow, closeModalWindow] = useModalState(false);

  // LOAD INIT MODAL DATA
  useEffect(() => {
    props.customer && dispatch(getItServiceTaskList());
    props.customer && dispatch(getActiveServiceRequests());
  }, [dispatch, props.customer]);

  return (
    <>
      <section className='page__section balance-panel balance-panel_guest'>
        <div className='balance-panel__inner'>
          <div className='balance-panel__state' />
          <div className='balance-panel__action'>
            <button
              type='button'
              className='balance-panel__link btn btn--solid balance-panel__link_add-guest'
              name='bank-transfer'
              onClick={openModalWindow}
            >
              <span className='balance-panel__link-text'>Оформить заявку</span>
            </button>
          </div>
        </div>
      </section>
      <ItServicesTasks />
      <ItServicesModal
        isModalWindowOpen={isModalWindowOpen}
        closeModalWindow={closeModalWindow}
        additionalClasses={['modal-window--fill-payments', 'modal-window--fill-payments_guest']}
        modalWindowTitle='Заявка на получение ИТ-услуги'
      />
    </>
  );
};

export default ItServices;
