import React from 'react';
import PropTypes from 'prop-types';
import PrintHistoryTableHead from './PrintHistoryTableHead';
import PrintHistoryTableBody from './PrintHistoryTableBody';

const PrintHistoryItem = props => {
  const { rows } = props;

  return (
    <div className='print__history-item'>
      <table className='print__history-table'>
        <PrintHistoryTableHead />
        <PrintHistoryTableBody rows={rows} />
      </table>
    </div>
  );
};

PrintHistoryItem.propTypes = {
  rows: PropTypes.array.isRequired
};

export default PrintHistoryItem;
