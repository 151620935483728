import { WorkplaceType, AreaType } from '../types';

export const PARK_KULTURY_5_AREAS = [
  {
    name: '501',
    uuid: 'area-501',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M2780 650V630H2860V830H2825V810H2805V830H2767V707H2770V710H2780V680H2775V650H2780Z',
    workplaces: [
      { uuid: 'wp-501-1', name: 'wp-501-1', position: { x: 2770, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-501-2', name: 'wp-501-2', position: { x: 2820, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-501-3', name: 'wp-501-3', position: { x: 2820, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-501-4', name: 'wp-501-4', position: { x: 2820, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '502',
    uuid: 'area-502',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M2687 820V707H2710V705H2740V707H2763V830H2690V820H2687Z',
    workplaces: [
      { uuid: 'wp-502-1', name: 'wp-502-1', position: { x: 2720, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-502-2', name: 'wp-502-2', position: { x: 2720, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '503',
    uuid: 'area-503',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M2617 810V707H2640V705H2670V707H2683V820H2660V830H2625V810H2617Z',
    workplaces: [
      { uuid: 'wp-503-1', name: 'wp-503-1', position: { x: 2640, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-503-2', name: 'wp-503-2', position: { x: 2640, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '504',
    uuid: 'area-504',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M2517 830V707H2550V705H2580V707H2613V810H2605V830H2517Z',
    workplaces: [
      { uuid: 'wp-504-1', name: 'wp-504-1', position: { x: 2520, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-504-2', name: 'wp-504-2', position: { x: 2520, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-504-3', name: 'wp-504-3', position: { x: 2570, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-504-4', name: 'wp-504-4', position: { x: 2570, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '505',
    uuid: 'area-505',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M2605 430H2610V590H2590V600H2550V625H2520V620H2500V420H2520V410H2605V430Z',
    workplaces: [
      { uuid: 'wp-505-1', name: 'wp-505-1', position: { x: 2570, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-505-2', name: 'wp-505-2', position: { x: 2570, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-505-3', name: 'wp-505-3', position: { x: 2570, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-505-4', name: 'wp-505-4', position: { x: 2570, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '506',
    uuid: 'area-506',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M2417 710H2450V705H2480V707H2513V830H2425V810H2417V710Z',
    workplaces: [
      { uuid: 'wp-506-1', name: 'wp-506-1', position: { x: 2420, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-506-2', name: 'wp-506-2', position: { x: 2420, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-506-3', name: 'wp-506-3', position: { x: 2470, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-506-4', name: 'wp-506-4', position: { x: 2470, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '507',
    uuid: 'area-507',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M2317 830V710H2350V705H2380V710H2413V810H2405V830H2317Z',
    workplaces: [
      { uuid: 'wp-507-1', name: 'wp-507-1', position: { x: 2320, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-507-2', name: 'wp-507-2', position: { x: 2320, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-507-3', name: 'wp-507-3', position: { x: 2370, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-507-4', name: 'wp-507-4', position: { x: 2370, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '508',
    uuid: 'area-508',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M2217 810V710H2250V705H2280V710H2313V830H2225V810H2217Z',
    workplaces: [
      { uuid: 'wp-508-1', name: 'wp-508-1', position: { x: 2220, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-508-2', name: 'wp-508-2', position: { x: 2220, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-508-3', name: 'wp-508-3', position: { x: 2270, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-508-4', name: 'wp-508-4', position: { x: 2270, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '509',
    uuid: 'area-509',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M2120 830V710H2140V705H2170V710H2213V810H2205V830H2120Z',
    workplaces: [
      { uuid: 'wp-509-1', name: 'wp-509-1', position: { x: 2120, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-509-2', name: 'wp-509-2', position: { x: 2120, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-509-3', name: 'wp-509-3', position: { x: 2170, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-509-4', name: 'wp-509-4', position: { x: 2170, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '510',
    uuid: 'area-510',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M2050 710V705H2080V710H2110V830H2040V820H2037V710H2050Z',
    workplaces: [
      { uuid: 'wp-510-1', name: 'wp-510-1', position: { x: 2070, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-510-2', name: 'wp-510-2', position: { x: 2070, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '512',
    uuid: 'area-512',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1967 410H2005V430H2070V440H2075V470H2070V523H1967V410Z',
    workplaces: [
      { uuid: 'wp-512-1', name: 'wp-512-1', position: { x: 2030, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-512-2', name: 'wp-512-2', position: { x: 1970, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-512-3', name: 'wp-512-3', position: { x: 1970, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '513',
    uuid: 'area-513',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1970 707V705H2000V710H2033V820H2025V810H2005V830H1967V707H1970Z',
    workplaces: [
      { uuid: 'wp-513-1', name: 'wp-513-1', position: { x: 1990, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-513-2', name: 'wp-513-2', position: { x: 1990, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '514',
    uuid: 'area-514',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1920 420V410H1963V613H1940V615H1910V613H1897V420H1920Z',
    workplaces: [
      { uuid: 'wp-514-1', name: 'wp-514-1', position: { x: 1920, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-514-2', name: 'wp-514-2', position: { x: 1920, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-514-3', name: 'wp-514-3', position: { x: 1920, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-514-4', name: 'wp-514-4', position: { x: 1920, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '515',
    uuid: 'area-515',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1887 820V707H1920V705H1950V707H1963V830H1890V820H1887Z',
    workplaces: [
      { uuid: 'wp-515-1', name: 'wp-515-1', position: { x: 1920, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-515-2', name: 'wp-515-2', position: { x: 1920, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '516',
    uuid: 'area-516',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1825 430V410H1890V420H1893V613H1880V615H1850V613H1825V610H1817V430H1825Z',
    workplaces: [
      { uuid: 'wp-516-1', name: 'wp-516-1', position: { x: 1850, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-516-2', name: 'wp-516-2', position: { x: 1850, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-516-3', name: 'wp-516-3', position: { x: 1850, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-516-4', name: 'wp-516-4', position: { x: 1850, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '517',
    uuid: 'area-517',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1767 730V707H1820V705H1850V707H1883V820H1860V830H1825V810H1805V830H1770V730H1767Z',
    workplaces: [
      { uuid: 'wp-517-1', name: 'wp-517-1', position: { x: 1770, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-517-2', name: 'wp-517-2', position: { x: 1770, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-517-3', name: 'wp-517-3', position: { x: 1840, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-517-4', name: 'wp-517-4', position: { x: 1840, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '518',
    uuid: 'area-518',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1717 613V410H1805V430H1813V610H1770V613H1760V615H1730V613H1717Z',
    workplaces: [
      { uuid: 'wp-518-1', name: 'wp-518-1', position: { x: 1770, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-518-2', name: 'wp-518-2', position: { x: 1770, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-518-3', name: 'wp-518-3', position: { x: 1770, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-518-4', name: 'wp-518-4', position: { x: 1770, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-518-5', name: 'wp-518-5', position: { x: 1720, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-518-6', name: 'wp-518-6', position: { x: 1720, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-518-7', name: 'wp-518-7', position: { x: 1720, y: 550, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '519',
    uuid: 'area-519',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1625 430V410H1713V613H1680V615H1650V613H1625V610H1620V430H1625Z',
    workplaces: [
      { uuid: 'wp-519-1', name: 'wp-519-1', position: { x: 1670, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-519-2', name: 'wp-519-2', position: { x: 1670, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-519-3', name: 'wp-519-3', position: { x: 1670, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-519-4', name: 'wp-519-4', position: { x: 1670, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-519-5', name: 'wp-519-5', position: { x: 1620, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-519-6', name: 'wp-519-6', position: { x: 1620, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-519-7', name: 'wp-519-7', position: { x: 1620, y: 510, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-519-8', name: 'wp-519-8', position: { x: 1620, y: 550, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '520',
    uuid: 'area-520',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1417 810V767H1450V765H1480V767H1540V765H1570V767H1613V810H1605V830H1425V810H1417Z',
    workplaces: [
      { uuid: 'wp-520-1', name: 'wp-520-1', position: { x: 1570, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-520-2', name: 'wp-520-2', position: { x: 1500, y: 790, angle: 0 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-520-3', name: 'wp-520-3', position: { x: 1420, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '522',
    uuid: 'area-522',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1327 820V767H1370V765H1400V767H1413V810H1405V830H1330V820H1327Z',
    workplaces: [
      { uuid: 'wp-522-1', name: 'wp-522-1', position: { x: 1330, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '523',
    uuid: 'area-523',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1217 810V767H1260V765H1290V767H1323V820H1310V830H1225V810H1217Z',
    workplaces: [
      { uuid: 'wp-523-1', name: 'wp-523-1', position: { x: 1220, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-523-2', name: 'wp-523-2', position: { x: 1280, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '524',
    uuid: 'area-524',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1117 553V410H1205V430H1220V530H1223V553H1180V555H1150V553H1117Z',
    workplaces: [
      { uuid: 'wp-524-1', name: 'wp-524-1', position: { x: 1120, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-524-2', name: 'wp-524-2', position: { x: 1120, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-524-3', name: 'wp-524-3', position: { x: 1180, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-524-4', name: 'wp-524-4', position: { x: 1180, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-524-5', name: 'wp-524-5', position: { x: 1180, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '525',
    uuid: 'area-525',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1087 820V767H1140V765H1170V767H1213V810H1205V830H1090V820H1087Z',
    workplaces: [
      { uuid: 'wp-525-1', name: 'wp-525-1', position: { x: 1090, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-525-2', name: 'wp-525-2', position: { x: 1170, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '526',
    uuid: 'area-526',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M1025 430V410H1113V553H1080V555H1050V553H1017V430H1025Z',
    workplaces: [
      { uuid: 'wp-526-1', name: 'wp-526-1', position: { x: 1020, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-526-2', name: 'wp-526-2', position: { x: 1020, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-526-3', name: 'wp-526-3', position: { x: 1070, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-526-4', name: 'wp-526-4', position: { x: 1070, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-526-5', name: 'wp-526-5', position: { x: 1070, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '527',
    uuid: 'area-527',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M967 790V767H1010V765H1040V767H1083V820H1060V830H1025V810H1005V830H970V790H967Z',
    workplaces: [
      { uuid: 'wp-527-1', name: 'wp-527-1', position: { x: 1040, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-527-2', name: 'wp-527-2', position: { x: 970, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '528',
    uuid: 'area-528',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M917 553V410H1005V430H1013V553H980V555H950V553H917Z',
    workplaces: [
      { uuid: 'wp-528-1', name: 'wp-528-1', position: { x: 920, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-528-2', name: 'wp-528-2', position: { x: 920, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-528-3', name: 'wp-528-3', position: { x: 970, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-528-4', name: 'wp-528-4', position: { x: 970, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-528-5', name: 'wp-528-5', position: { x: 970, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '529',
    uuid: 'area-529',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M825 430V410H913V553H880V555H850V553H820V430H825Z',
    workplaces: [
      { uuid: 'wp-529-1', name: 'wp-529-1', position: { x: 820, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-529-2', name: 'wp-529-2', position: { x: 820, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-529-3', name: 'wp-529-3', position: { x: 870, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-529-4', name: 'wp-529-4', position: { x: 870, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-529-5', name: 'wp-529-5', position: { x: 870, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '530',
    uuid: 'area-530',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M717 620V410H805V430H810V610H750V625H720V620H717Z',
    workplaces: [
      { uuid: 'wp-530-1', name: 'wp-530-1', position: { x: 720, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-530-2', name: 'wp-530-2', position: { x: 720, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-530-3', name: 'wp-530-3', position: { x: 770, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-530-4', name: 'wp-530-4', position: { x: 770, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-530-5', name: 'wp-530-5', position: { x: 770, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-530-6', name: 'wp-530-6', position: { x: 770, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '531',
    uuid: 'area-531',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M687 687H720V685H750V687H763V740H760V830H690V820H687V687Z',
    workplaces: [
      { uuid: 'wp-531-1', name: 'wp-531-1', position: { x: 720, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-531-2', name: 'wp-531-2', position: { x: 720, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-531-3', name: 'wp-531-3', position: { x: 720, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '532',
    uuid: 'area-532',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M625 430V410H713V620H710V625H680V610H620V430H625Z',
    workplaces: [
      { uuid: 'wp-532-1', name: 'wp-532-1', position: { x: 620, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-532-2', name: 'wp-532-2', position: { x: 620, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-532-3', name: 'wp-532-3', position: { x: 670, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-532-4', name: 'wp-532-4', position: { x: 670, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-532-5', name: 'wp-532-5', position: { x: 670, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-532-6', name: 'wp-532-6', position: { x: 670, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '533',
    uuid: 'area-533',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M617 810V687H620V685H650V687H683V820H660V830H625V810H617Z',
    workplaces: [
      { uuid: 'wp-533-1', name: 'wp-533-1', position: { x: 640, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-533-2', name: 'wp-533-2', position: { x: 640, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-533-3', name: 'wp-533-3', position: { x: 640, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '534',
    uuid: 'area-534',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M540 410H605V430H610V610H605V620H580V625H550V620H540V410Z',
    workplaces: [
      { uuid: 'wp-534-1', name: 'wp-534-1', position: { x: 570, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-534-2', name: 'wp-534-2', position: { x: 570, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-534-3', name: 'wp-534-3', position: { x: 570, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-534-4', name: 'wp-534-4', position: { x: 570, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '535',
    uuid: 'area-535',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M517 830V687H550V685H580V687H613V810H605V830H517Z',
    workplaces: [
      { uuid: 'wp-535-1', name: 'wp-535-1', position: { x: 570, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-535-2', name: 'wp-535-2', position: { x: 570, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-535-3', name: 'wp-535-3', position: { x: 520, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-535-4', name: 'wp-535-4', position: { x: 520, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '536',
    uuid: 'area-536',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M510 420H530V625H500V620H470V410H510V420Z',
    workplaces: [
      { uuid: 'wp-536-1', name: 'wp-536-1', position: { x: 490, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-536-2', name: 'wp-536-2', position: { x: 490, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-536-3', name: 'wp-536-3', position: { x: 490, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-536-4', name: 'wp-536-4', position: { x: 490, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '537',
    uuid: 'area-537',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M417 810V687H450V685H480V687H513V830H425V810H417Z',
    workplaces: [
      { uuid: 'wp-537-1', name: 'wp-537-1', position: { x: 470, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-537-2', name: 'wp-537-2', position: { x: 470, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-537-3', name: 'wp-537-3', position: { x: 420, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-537-4', name: 'wp-537-4', position: { x: 420, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '538',
    uuid: 'area-538',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M317 830V690H340V687H350V685H380V687H413V810H405V830H317Z',
    workplaces: [
      { uuid: 'wp-538-1', name: 'wp-538-1', position: { x: 370, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-538-2', name: 'wp-538-2', position: { x: 370, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-538-3', name: 'wp-538-3', position: { x: 320, y: 690, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-538-4', name: 'wp-538-4', position: { x: 320, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-538-5', name: 'wp-538-5', position: { x: 320, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: '539',
    uuid: 'area-539',
    type: AreaType.CABINET,
    position: { x: 7, y: 57 },
    svgPath: 'M220 810V687H240V685H270V687H290V690H313V830H225V810H220Z',
    workplaces: [
      { uuid: 'wp-539-1', name: 'wp-539-1', position: { x: 270, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-539-2', name: 'wp-539-2', position: { x: 270, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-539-3', name: 'wp-539-3', position: { x: 220, y: 690, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-539-4', name: 'wp-539-4', position: { x: 220, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'wp-539-5', name: 'wp-539-5', position: { x: 220, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'PK-4-OP-1',
    uuid: 'area-pk-4-op-sp-1',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    //todo вставить
    svgPath: '',
    workplaces: [
      { name: 'К501', uuid: 'pk4-op-1-1', type: WorkplaceType.DEFAULT, position: { x: 1520, y: 510, angle: 90 } },
      { name: 'К502', uuid: 'pk4-op-1-2', type: WorkplaceType.DEFAULT, position: { x: 1520, y: 470, angle: 90 } },
      { name: 'К503', uuid: 'pk4-op-1-3', type: WorkplaceType.DEFAULT, position: { x: 1520, y: 430, angle: 90 } },
      {
        name: 'К504',
        textRotate: true,
        uuid: 'pk4-op-1-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1480, y: 510, angle: -90 }
      },
      {
        name: 'К505',
        textRotate: true,
        uuid: 'pk4-op-1-5',
        type: WorkplaceType.DEFAULT,
        position: { x: 1480, y: 470, angle: -90 }
      },
      {
        name: 'К506',
        textRotate: true,
        uuid: 'pk4-op-1-6',
        type: WorkplaceType.DEFAULT,
        position: { x: 1480, y: 430, angle: -90 }
      },
      //
      { name: 'К507', uuid: 'pk4-op-2-1', type: WorkplaceType.DEFAULT, position: { x: 1410, y: 510, angle: 90 } },
      { name: 'К508', uuid: 'pk4-op-2-2', type: WorkplaceType.DEFAULT, position: { x: 1410, y: 470, angle: 90 } },
      { name: 'К509', uuid: 'pk4-op-2-3', type: WorkplaceType.DEFAULT, position: { x: 1410, y: 430, angle: 90 } },
      {
        name: 'К510',
        textRotate: true,
        uuid: 'pk4-op-2-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1370, y: 510, angle: -90 }
      },
      {
        name: 'К511',
        textRotate: true,
        uuid: 'pk4-op-2-5',
        type: WorkplaceType.DEFAULT,
        position: { x: 1370, y: 470, angle: -90 }
      },
      {
        name: 'К512',
        textRotate: true,
        uuid: 'pk4-op-2-6',
        type: WorkplaceType.DEFAULT,
        position: { x: 1370, y: 430, angle: -90 }
      },
      //
      { name: 'К513', uuid: 'pk4-op-3-1', type: WorkplaceType.DEFAULT, position: { x: 1300, y: 510, angle: 90 } },
      { name: 'К514', uuid: 'pk4-op-3-2', type: WorkplaceType.DEFAULT, position: { x: 1300, y: 470, angle: 90 } },
      { name: 'К515', uuid: 'pk4-op-3-3', type: WorkplaceType.DEFAULT, position: { x: 1300, y: 430, angle: 90 } },
      {
        name: 'К516',
        textRotate: true,
        uuid: 'pk4-op-3-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1260, y: 510, angle: -90 }
      },
      {
        name: 'К517',
        textRotate: true,
        uuid: 'pk4-op-3-5',
        type: WorkplaceType.DEFAULT,
        position: { x: 1260, y: 470, angle: -90 }
      },
      {
        name: 'К518',
        textRotate: true,
        uuid: 'pk4-op-3-6',
        type: WorkplaceType.DEFAULT,
        position: { x: 1260, y: 430, angle: -90 }
      }
    ]
  },
  {
    uuid: 'PS-5',
    type: AreaType.CABINET,
    zones: [
      {
        position: { x: 2030, y: 230 },
        svgPath: 'M2200 390H2030V230H2200V390Z'
      },
      {
        position: { x: 800, y: 740 },
        svgPath: 'M963 740H800V830H960V790H963V740Z'
      },
      {
        position: { x: 1617, y: 703 },
        svgPath: 'M1760 830H1625V810H1617V763V703H1763V730H1760V830Z'
      },
      //Зоны переговорных комнат
      {
        position: { x: 1270, y: 670 },
        svgPath: 'M1300 670H1270V710H1300V670Z'
      },
      {
        position: { x: 1310, y: 670 },
        svgPath: 'M1340 670H1310V710H1340V670Z'
      },
      {
        position: { x: 1350, y: 670 },
        svgPath: 'M1380 670H1350V710H1380V670Z'
      },
      {
        position: { x: 1390, y: 670 },
        svgPath: 'M1420 670H1390V710H1420V670Z'
      }
    ],
    name: 'Нефиксированное рабочее место'
  }
];
export const PARK_KULTURY_4_AREAS = [
  {
    uuid: 'area-401',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '401',
    svgPath: 'M2780 650V630H2860V830H2825V810H2805V830H2767V707H2770V710H2780V680H2775V650H2780Z',
    workplaces: [
      { uuid: 'work-401-1', name: 'work-401', position: { x: 2820, y: 650, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-401-2', name: 'work-401', position: { x: 2820, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-401-3', name: 'work-401', position: { x: 2820, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-401-4', name: 'work-401', position: { x: 2820, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-401-5', name: 'work-401', position: { x: 2770, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-402',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '402',
    svgPath: 'M2687 820V707H2710V705H2740V707H2763V830H2690V820H2687Z',
    workplaces: [
      { uuid: 'work-402-1', name: 'work-402', position: { x: 2720, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-402-2', name: 'work-402', position: { x: 2720, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-403',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '403',
    svgPath: 'M2617 810V707H2640V705H2670V707H2683V820H2660V830H2625V810H2617Z',
    workplaces: [
      { uuid: 'work-403-1', name: 'work-403', position: { x: 2640, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-403-2', name: 'work-403', position: { x: 2640, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-404',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '404',
    svgPath: 'M2517 830V707H2550V705H2580V707H2613V810H2605V830H2517Z',
    workplaces: [
      { uuid: 'work-404-1', name: 'work-404', position: { x: 2570, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-404-2', name: 'work-404', position: { x: 2570, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-404-3', name: 'work-404', position: { x: 2520, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-404-4', name: 'work-404', position: { x: 2520, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-405',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '405',
    svgPath: 'M2520 420V410H2580V600H2530V625H2500V420H2520Z',
    workplaces: [
      { uuid: 'work-405-1', name: 'work-405', position: { x: 2540, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-405-2', name: 'work-405', position: { x: 2540, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-405-3', name: 'work-405', position: { x: 2540, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-405-4', name: 'work-405', position: { x: 2540, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-406',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '406',
    svgPath: 'M2417 710H2450V705H2480V707H2513V830H2425V810H2417V710Z',
    workplaces: [
      { uuid: 'work-406-1', name: 'work-406', position: { x: 2470, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-406-2', name: 'work-406', position: { x: 2470, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-406-3', name: 'work-406', position: { x: 2420, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-406-4', name: 'work-406', position: { x: 2420, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-407',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '407',
    svgPath: 'M2317 830V710H2350V705H2380V710H2413V810H2405V830H2317Z',
    workplaces: [
      { uuid: 'work-407-1', name: 'work-407', position: { x: 2370, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-407-2', name: 'work-407', position: { x: 2370, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-407-3', name: 'work-407', position: { x: 2320, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-407-4', name: 'work-407', position: { x: 2320, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'PS-4',
    type: AreaType.CABINET,
    name: 'Нефиксированное рабочее место',
    zones: [
      {
        position: { x: 2030, y: 210 },
        svgPath: 'M2080 230H2030V390H2200V230H2190V210H2080V230Z'
      },
      {
        position: { x: 2170, y: 410 },
        svgPath: 'M2225 430H2170V610H2340V410H2225V430Z'
      },
      {
        position: { x: 800, y: 740 },
        svgPath: 'M963 740H800V830H960V790H963V740Z'
      },
      {
        position: { x: 1617, y: 703 },
        svgPath: 'M1760 830H1625V810H1617V763V703H1763V730H1760V830Z'
      },
      //Зоны переговорных комнат
      {
        position: { x: 1270, y: 670 },
        svgPath: 'M1300 670H1270V710H1300V670Z'
      },
      {
        position: { x: 1310, y: 670 },
        svgPath: 'M1340 670H1310V710H1340V670Z'
      },
      {
        position: { x: 1350, y: 670 },
        svgPath: 'M1380 670H1350V710H1380V670Z'
      },
      {
        position: { x: 1390, y: 670 },
        svgPath: 'M1420 670H1390V710H1420V670Z'
      },
      {
        position: { x: 2770, y: 500 },
        svgPath: 'M2810 500H2770V530H2810V500Z'
      },
      {
        position: { x: 2770, y: 540 },
        svgPath: 'M2810 540H2770V570H2810V540Z'
      },
      {
        position: { x: 2770, y: 580 },
        svgPath: 'M2810 580H2770V610H2810V580Z'
      }
    ]
  },
  {
    uuid: 'area-408',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '408',
    svgPath: 'M2217 810V710H2250V705H2280V710H2313V830H2225V810H2217Z',
    workplaces: [
      { uuid: 'work-408-1', name: 'work-408', position: { x: 2270, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-408-2', name: 'work-408', position: { x: 2270, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-408-3', name: 'work-408', position: { x: 2220, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-408-4', name: 'work-408', position: { x: 2220, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-409',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '409',
    svgPath: 'M2120 830V710H2140V705H2170V710H2213V810H2205V830H2120Z',
    workplaces: [
      { uuid: 'work-409-1', name: 'work-409', position: { x: 2170, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-409-2', name: 'work-409', position: { x: 2170, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-409-3', name: 'work-409', position: { x: 2120, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-409-4', name: 'work-409', position: { x: 2120, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-410',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '410',
    svgPath: 'M2007 810V710H2080V705H2110V830H2040V820H2025V810H2007Z',
    workplaces: [
      { uuid: 'work-410-1', name: 'work-410', position: { x: 2070, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-410-2', name: 'work-410', position: { x: 2070, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-410-3', name: 'work-410', position: { x: 2010, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-410-4', name: 'work-410', position: { x: 2010, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-411',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '411',
    svgPath: 'M1967 613V527H2070V580H2075V610H2070V613H2025V610H2005V613H1967Z',
    workplaces: [
      { uuid: 'work-411-1', name: 'work-411', position: { x: 2030, y: 530, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-411-2', name: 'work-411', position: { x: 1970, y: 530, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-411-3', name: 'work-411', position: { x: 1970, y: 570, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-412',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '412',
    svgPath: 'M1967 410H2005V430H2070V440H2075V470H2070V523H1967V410Z',
    workplaces: [
      { uuid: 'work-412-1', name: 'work-412', position: { x: 2030, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-412-2', name: 'work-412', position: { x: 1970, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-412-3', name: 'work-412', position: { x: 1970, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-413',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '413',
    svgPath: 'M1887 820V707H1920V705H1950V707H1960V710H2003V810H2005V830H1890V820H1887Z',
    workplaces: [
      { uuid: 'work-413-1', name: 'work-413', position: { x: 1960, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-413-2', name: 'work-413', position: { x: 1960, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-413-3', name: 'work-413', position: { x: 1890, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-413-4', name: 'work-413', position: { x: 1890, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-413',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '413',
    svgPath: 'M1887 820V707H1920V705H1950V707H1960V710H2003V810H2005V830H1890V820H1887Z',
    workplaces: [
      { uuid: 'work-413-1', name: 'work-413', position: { x: 1960, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-413-2', name: 'work-413', position: { x: 1960, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-413-3', name: 'work-413', position: { x: 1890, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-413-4', name: 'work-413', position: { x: 1890, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-414',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '414',
    svgPath: 'M1920 420V410H1963V613H1940V615H1910V613H1897V420H1920Z',
    workplaces: [
      { uuid: 'work-414-1', name: 'work-414', position: { x: 1920, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-414-2', name: 'work-414', position: { x: 1920, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-414-3', name: 'work-414', position: { x: 1920, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-414-4', name: 'work-414', position: { x: 1920, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-415',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '415',
    svgPath: 'M1767 730V707H1820V705H1850V707H1883V820H1860V830H1825V810H1805V830H1770V730H1767Z',
    workplaces: [
      { uuid: 'work-415-1', name: 'work-415', position: { x: 1840, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-415-2', name: 'work-415', position: { x: 1840, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-415-3', name: 'work-415', position: { x: 1770, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-415-4', name: 'work-415', position: { x: 1770, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-416',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '416',
    svgPath: 'M1797 610V420H1805V430H1825V410H1890V420H1893V613H1880V615H1850V613H1825V610H1797Z',
    workplaces: [
      { uuid: 'work-416-1', name: 'work-416', position: { x: 1850, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-416-2', name: 'work-416', position: { x: 1850, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-416-3', name: 'work-416', position: { x: 1850, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-416-4', name: 'work-416', position: { x: 1850, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-417',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '417',
    svgPath: 'M1717 613V410H1790V420H1793V610H1770V613H1760V615H1730V613H1717Z',
    workplaces: [
      { uuid: 'work-417-1', name: 'work-417', position: { x: 1750, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-417-2', name: 'work-417', position: { x: 1750, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-417-3', name: 'work-417', position: { x: 1750, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-417-4', name: 'work-417', position: { x: 1750, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-418',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '418',
    svgPath: 'M1625 430V410H1713V613H1680V615H1650V613H1625V610H1620V430H1625Z',
    workplaces: [
      { uuid: 'work-418-1', name: 'work-418', position: { x: 1670, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-418-2', name: 'work-418', position: { x: 1670, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-418-3', name: 'work-418', position: { x: 1670, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-418-4', name: 'work-418', position: { x: 1670, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-418-5', name: 'work-418', position: { x: 1620, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-418-6', name: 'work-418', position: { x: 1620, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-419',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '419',
    svgPath: 'M1417 810V767H1450V765H1480V767H1540V765H1570V767H1613V810H1605V830H1425V810H1417Z',
    workplaces: [
      { uuid: 'work-419-1', name: 'work-419', position: { x: 1571, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-419-2', name: 'work-419', position: { x: 1496, y: 790, angle: 360 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-419-3', name: 'work-419', position: { x: 1421, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-420',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '420',
    svgPath: 'M1517 553V410H1580V553H1550V555H1520V553H1517Z',
    workplaces: [
      { uuid: 'work-420-1', name: 'work-420', position: { x: 1540, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-420-2', name: 'work-420', position: { x: 1540, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-420-3', name: 'work-420', position: { x: 1540, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-422',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '422',
    svgPath: 'M1425 430V410H1513V553H1470V555H1440V553H1417V430H1425Z',
    workplaces: [
      { uuid: 'work-422-1', name: 'work-422', position: { x: 1470, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-422-2', name: 'work-422', position: { x: 1470, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-422-3', name: 'work-422', position: { x: 1420, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-422-4', name: 'work-422', position: { x: 1420, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-423',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '423',
    svgPath: 'M1327 820V767H1370V765H1400V767H1413V810H1405V830H1330V820H1327Z',
    workplaces: [
      { uuid: 'work-423-1', name: 'work-423', position: { x: 1330, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-424',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '424',
    svgPath: 'M1317 553V410H1405V430H1413V553H1370V555H1340V553H1317Z',
    workplaces: [
      { uuid: 'work-424-1', name: 'work-424', position: { x: 1370, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-424-2', name: 'work-424', position: { x: 1370, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-424-3', name: 'work-424', position: { x: 1320, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-424-4', name: 'work-424', position: { x: 1320, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-425',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '425',
    svgPath: 'M1217 810V767H1260V765H1290V767H1323V820H1310V830H1225V810H1217Z',
    workplaces: [
      { uuid: 'work-425-1', name: 'work-425', position: { x: 1280, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-425-2', name: 'work-425', position: { x: 1220, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-426',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '426',
    svgPath: 'M1117 553V410H1205V430H1225V410H1313V553H1180V555H1150V553H1117Z',
    workplaces: [
      { uuid: 'work-426-1', name: 'work-426', position: { x: 1270, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-426-2', name: 'work-426', position: { x: 1270, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-426-3', name: 'work-426', position: { x: 1215, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-426-4', name: 'work-426', position: { x: 1215, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-426-5', name: 'work-426', position: { x: 1175, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-426-6', name: 'work-426', position: { x: 1175, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-426-7', name: 'work-426', position: { x: 1120, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-426-8', name: 'work-426', position: { x: 1120, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-427',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '427',
    svgPath: 'M1087 820V767H1140V765H1170V767H1213V810H1205V830H1090V820H1087Z',
    workplaces: [
      { uuid: 'work-427-1', name: 'work-427', position: { x: 1170, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-427-2', name: 'work-427', position: { x: 1090, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-429',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '429',
    svgPath: 'M967 790V767H1010V765H1040V767H1083V820H1060V830H1025V810H1005V830H970V790H967Z',
    workplaces: [
      { uuid: 'work-429-1', name: 'work-429', position: { x: 1040, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-429-2', name: 'work-429', position: { x: 970, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-430',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '430',
    svgPath: 'M1025 430V410H1113V553H1080V555H1050V553H1017V430H1025Z',
    workplaces: [
      { uuid: 'work-430-1', name: 'work-430', position: { x: 1070, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-430-2', name: 'work-430', position: { x: 1070, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-430-3', name: 'work-430', position: { x: 1020, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-430-4', name: 'work-430', position: { x: 1020, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-431',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '431',
    svgPath: 'M825 430V410H1005V430H1013V553H930V555H900V553H820V430H825Z',
    workplaces: [
      { uuid: 'work-431-1', name: 'work-431', position: { x: 970, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-431-2', name: 'work-431', position: { x: 970, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-431-3', name: 'work-431', position: { x: 915, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-431-4', name: 'work-431', position: { x: 915, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-431-5', name: 'work-431', position: { x: 875, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-431-6', name: 'work-431', position: { x: 875, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-431-7', name: 'work-431', position: { x: 820, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-431-8', name: 'work-431', position: { x: 820, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-432',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '432',
    svgPath: 'M625 430V410H805V430H810V610H750V625H720V620H710V625H680V610H620V430H625Z',
    workplaces: [
      { uuid: 'work-432-1', name: 'work-432', position: { x: 770, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-432-2', name: 'work-432', position: { x: 770, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-432-3', name: 'work-432', position: { x: 770, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-432-4', name: 'work-432', position: { x: 770, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-432-5', name: 'work-432', position: { x: 715, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-432-6', name: 'work-432', position: { x: 675, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-432-7', name: 'work-432', position: { x: 620, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-432-8', name: 'work-432', position: { x: 620, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-432-9', name: 'work-432', position: { x: 620, y: 510, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-432-10', name: 'work-432', position: { x: 620, y: 550, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-433',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '433',
    svgPath: 'M657 830V687H690V685H720V687H763V740H760V830H690V820H660V830H657Z',
    workplaces: [
      { uuid: 'work-433-1', name: 'work-433', position: { x: 720, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-433-2', name: 'work-433', position: { x: 720, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-433-3', name: 'work-433', position: { x: 720, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-433-4', name: 'work-433', position: { x: 660, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-433-5', name: 'work-433', position: { x: 660, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-435',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '435',
    svgPath: 'M587 830V687H620V685H650V687H653V830H625V810H605V830H587Z',
    workplaces: [
      { uuid: 'work-435-1', name: 'work-433', position: { x: 590, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-435-2', name: 'work-433', position: { x: 590, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-436',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '436',
    svgPath: 'M540 410H605V430H610V610H605V620H580V625H550V620H540V410Z',
    workplaces: [
      { uuid: 'work-436-1', name: 'work-436', position: { x: 570, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-436-2', name: 'work-436', position: { x: 570, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-436-3', name: 'work-436', position: { x: 570, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-436-4', name: 'work-436', position: { x: 570, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-437',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '437',
    svgPath: 'M517 687H520V685H550V687H583V830H517V687Z',
    workplaces: [
      { uuid: 'work-437-1', name: 'work-437', position: { x: 540, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-437-2', name: 'work-437', position: { x: 540, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-437-3', name: 'work-437', position: { x: 540, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-438',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '438',
    svgPath: 'M510 420H530V625H500V620H470V410H510V420Z',
    workplaces: [
      { uuid: 'work-438-1', name: 'work-438', position: { x: 490, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-438-2', name: 'work-438', position: { x: 490, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-438-3', name: 'work-438', position: { x: 490, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-438-4', name: 'work-438', position: { x: 490, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-439',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '439',
    svgPath: 'M417 810V687H450V685H480V687H513V830H425V810H417Z',
    workplaces: [
      { uuid: 'work-439-1', name: 'work-439', position: { x: 470, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-439-2', name: 'work-439', position: { x: 470, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-439-3', name: 'work-439', position: { x: 470, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-439-4', name: 'work-439', position: { x: 420, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-439-5', name: 'work-439', position: { x: 420, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-440',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '440',
    svgPath: 'M225 810H220V687H290V690H340V687H350V685H380V687H413V810H405V830H225V810Z',
    workplaces: [
      { uuid: 'work-440-1', name: 'work-440', position: { x: 370, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-440-2', name: 'work-440', position: { x: 370, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-440-3', name: 'work-440', position: { x: 315, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-440-4', name: 'work-440', position: { x: 315, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-440-5', name: 'work-440', position: { x: 275, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-440-6', name: 'work-440', position: { x: 275, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-440-7', name: 'work-440', position: { x: 220, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-440-8', name: 'work-440', position: { x: 220, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  }

  //Позже добавим преговорки
  // {
  //   uuid: 'area-M101',
  //   type: AreaType.MEETING_ROOM,
  //   position: { x: 410, y: 265 },
  //   size: { width: 90, height: 135 },
  //   name: { title: 'M11', position: { x: 150, y: 150 } },
  //   svgPath: 'M420 267V265H450V267H500V400H410V267H420Z',
  //   workplaces: [{ uuid: 'work-m11-1', position: { x: 420, y: 280, angle: 0 }, type: WorkplaceType.MEETING }],
  // },
  // {
  //   uuid: 'area-M102',
  //   type: AreaType.MEETING_ROOM,
  //   position: { x: 510, y: 265 },
  //   size: { width: 90, height: 135 },
  //   name: { title: 'M12', position: { x: 150, y: 150 } },
  //   svgPath: 'M520 267V265H550V267H570V270H600V400H510V267H520Z',
  //   workplaces: [{ uuid: 'work-m12-1', position: { x: 520, y: 280, angle: 0 }, type: WorkplaceType.MEETING }],
  // },
];
export const PARK_KULTURY_6_AREAS = [
  {
    uuid: 'area-601',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '601',
    svgPath: 'M2780 630H2860V830H2825V810H2805V830H2767V707H2770V710H2780V680H2775V650H2780V630Z',
    workplaces: [
      { uuid: 'work-601-1', name: 'work-601', position: { x: 2820, y: 650, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-601-2', name: 'work-601', position: { x: 2820, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-601-3', name: 'work-601', position: { x: 2820, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-601-4', name: 'work-601', position: { x: 2820, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-601-5', name: 'work-601', position: { x: 2770, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-602',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '602',
    svgPath: 'M2687 820V707H2710V705H2740V707H2763V830H2690V820H2687Z',
    workplaces: [
      { uuid: 'work-602-1', name: 'work-602', position: { x: 2720, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-602-2', name: 'work-602', position: { x: 2720, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-603',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '603',
    svgPath: 'M2617 810V707H2640V705H2670V707H2683V820H2660V830H2625V810H2617Z',
    workplaces: [
      { uuid: 'work-603-1', name: 'work-603', position: { x: 2620, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-603-2', name: 'work-603', position: { x: 2620, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-604',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '604',
    svgPath: 'M2517 830V707H2550V705H2580V707H2613V810H2605V830H2517Z',
    workplaces: [
      { uuid: 'work-604-1', name: 'work-604', position: { x: 2570, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-604-2', name: 'work-604', position: { x: 2570, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-604-3', name: 'work-604', position: { x: 2520, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-604-4', name: 'work-604', position: { x: 2520, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-606',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '606',
    svgPath: 'M2450 710V705H2480V707H2513V830H2425V810H2417V710H2450Z',
    workplaces: [
      { uuid: 'work-606-1', name: 'work-606', position: { x: 2470, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-606-2', name: 'work-606', position: { x: 2470, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-606-3', name: 'work-606', position: { x: 2420, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-606-4', name: 'work-606', position: { x: 2420, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  //
  {
    uuid: 'area-607',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '607',
    svgPath: 'M2350 710V705H2380V710H2413V810H2405V830H2317V710H2350Z',
    workplaces: [
      { uuid: 'work-607-1', name: 'work-607', position: { x: 2370, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-607-2', name: 'work-607', position: { x: 2370, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-607-3', name: 'work-607', position: { x: 2320, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-607-4', name: 'work-607', position: { x: 2320, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-608',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '608',
    svgPath: 'M2250 710V705H2280V710H2313V830H2225V810H2217V710H2250Z',
    workplaces: [
      { uuid: 'work-608-1', name: 'work-608', position: { x: 2270, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-608-2', name: 'work-608', position: { x: 2270, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-608-3', name: 'work-608', position: { x: 2220, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-608-4', name: 'work-608', position: { x: 2220, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-609',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '609',
    svgPath: 'M2150 710V705H2180V710H2213V810H2205V830H2120V710H2150Z',
    workplaces: [
      { uuid: 'work-609-1', name: 'work-609', position: { x: 2170, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-609-2', name: 'work-609', position: { x: 2170, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-609-3', name: 'work-609', position: { x: 2120, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-609-4', name: 'work-609', position: { x: 2120, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-610',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '610',
    svgPath: 'M2050 710V705H2080V710H2110V830H2040V820H2037V710H2050Z',
    workplaces: [
      { uuid: 'work-610-1', name: 'work-610', position: { x: 2070, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-610-2', name: 'work-610', position: { x: 2070, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-613',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '613',
    svgPath: 'M1970 707V705H2000V710H2033V820H2025V810H2005V830H1967V707H1970Z',
    workplaces: [
      { uuid: 'work-613-1', name: 'work-613', position: { x: 1990, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-613-2', name: 'work-613', position: { x: 1990, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-615',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '615',
    svgPath: 'M1887 820V707H1920V705H1950V707H1963V830H1890V820H1887Z',
    workplaces: [
      { uuid: 'work-615-1', name: 'work-615', position: { x: 1890, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-615-2', name: 'work-615', position: { x: 1890, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-617',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '617',
    svgPath: 'M1767 730V707H1820V705H1850V707H1883V820H1860V830H1825V810H1805V830H1770V730H1767Z',
    workplaces: [
      { uuid: 'work-617-1', name: 'work-617', position: { x: 1840, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-617-2', name: 'work-617', position: { x: 1840, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-617-3', name: 'work-617', position: { x: 1770, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-617-4', name: 'work-617', position: { x: 1770, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  //ОШИБКИ В PLACES слое по дизайну
  {
    uuid: 'area-620',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '620',
    svgPath: 'M1605 810V830H1530V820H1527V767H1540V765H1570V767H1613V810H1605Z',
    workplaces: [
      { uuid: 'work-620-1', name: 'work-620', position: { x: 1570, y: 780, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  //ОШИБКИ В PLACES слое по дизайну
  {
    uuid: 'area-621',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '621',
    svgPath: 'M1417 810V767H1450V765H1480V767H1523V820H1510V830H1425V810H1417Z',
    workplaces: [
      { uuid: 'work-621-1', name: 'work-621', position: { x: 1480, y: 780, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-621-2', name: 'work-621', position: { x: 1420, y: 780, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-622',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '622',
    svgPath: 'M1327 820V767H1370V765H1400V767H1413V810H1405V830H1330V820H1327Z',
    workplaces: [
      { uuid: 'work-622-1', name: 'work-622', position: { x: 1330, y: 780, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-623',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '623',
    svgPath: 'M1217 810V767H1260V765H1290V767H1323V820H1310V830H1225V810H1217Z',
    workplaces: [
      { uuid: 'work-623-1', name: 'work-623', position: { x: 1280, y: 780, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-623-2', name: 'work-623', position: { x: 1220, y: 780, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-625',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '625',
    svgPath: 'M1087 820V767H1140V765H1170V767H1213V810H1205V830H1090V820H1087Z',
    workplaces: [
      { uuid: 'work-625-1', name: 'work-625', position: { x: 1170, y: 780, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-625-2', name: 'work-625', position: { x: 1090, y: 780, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-627',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '627',
    svgPath: 'M967 790V767H1010V765H1040V767H1083V820H1060V830H1025V810H1005V830H970V790H967Z',
    workplaces: [
      { uuid: 'work-627-1', name: 'work-627', position: { x: 1040, y: 780, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-627-2', name: 'work-627', position: { x: 970, y: 780, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-631',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '631',
    svgPath: 'M687 687H720V685H750V687H763V740H760V830H690V820H687V687Z',
    workplaces: [
      { uuid: 'work-631-1', name: 'work-631', position: { x: 690, y: 690, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-631-2', name: 'work-631', position: { x: 690, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-631-3', name: 'work-631', position: { x: 690, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-633',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '633',
    svgPath: 'M617 810V687H620V685H650V687H683V820H660V830H625V810H617Z',
    workplaces: [
      { uuid: 'work-633-1', name: 'work-633', position: { x: 640, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-633-2', name: 'work-633', position: { x: 640, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-633-3', name: 'work-633', position: { x: 640, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-635',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '635',
    svgPath: 'M517 830V687H550V685H580V687H613V810H605V830H517Z',
    workplaces: [
      { uuid: 'work-635-1', name: 'work-635', position: { x: 570, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-635-2', name: 'work-635', position: { x: 570, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-635-3', name: 'work-635', position: { x: 520, y: 690, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-635-4', name: 'work-635', position: { x: 520, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-635-5', name: 'work-635', position: { x: 520, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-637',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '637',
    svgPath: 'M450 687V685H480V687H513V830H425V810H417V687H450Z',
    workplaces: [
      { uuid: 'work-637-1', name: 'work-635', position: { x: 470, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-637-2', name: 'work-637', position: { x: 470, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-637-3', name: 'work-637', position: { x: 470, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-637-4', name: 'work-637', position: { x: 420, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-637-5', name: 'work-637', position: { x: 420, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-638',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '638',
    svgPath: 'M317 830V690H340V687H350V685H380V687H413V810H405V830H317Z',
    workplaces: [
      { uuid: 'work-638-1', name: 'work-638', position: { x: 370, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-638-2', name: 'work-638', position: { x: 370, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-638-3', name: 'work-638', position: { x: 320, y: 690, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-638-4', name: 'work-638', position: { x: 320, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-638-5', name: 'work-638', position: { x: 320, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-639',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '639',
    svgPath: 'M220 810V687H240V685H270V687H290V690H313V830H225V810H220Z',
    workplaces: [
      { uuid: 'work-639-1', name: 'work-639', position: { x: 270, y: 690, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-639-2', name: 'work-639', position: { x: 270, y: 730, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-639-3', name: 'work-639', position: { x: 270, y: 770, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-639-4', name: 'work-639', position: { x: 220, y: 730, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-639-5', name: 'work-639', position: { x: 220, y: 770, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-636',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '636',
    svgPath: 'M470 620V410H510V420H530V625H500V620H470Z',
    workplaces: [
      { uuid: 'work-636-1', name: 'work-636', position: { x: 470, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-636-2', name: 'work-636', position: { x: 470, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-636-3', name: 'work-636', position: { x: 470, y: 510, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-636-4', name: 'work-636', position: { x: 470, y: 550, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-634',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '634',
    svgPath: 'M540 620V410H605V430H610V610H605V620H580V625H550V620H540Z',
    workplaces: [
      { uuid: 'work-634-1', name: 'work-634', position: { x: 570, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-634-2', name: 'work-634', position: { x: 570, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-634-3', name: 'work-634', position: { x: 570, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-634-4', name: 'work-634', position: { x: 570, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-632',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '632',
    svgPath: 'M625 430V410H713V620H710V625H680V610H620V430H625Z',
    workplaces: [
      { uuid: 'work-632-1', name: 'work-632', position: { x: 620, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-632-2', name: 'work-632', position: { x: 620, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-632-3', name: 'work-632', position: { x: 620, y: 510, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-632-4', name: 'work-632', position: { x: 670, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-632-5', name: 'work-632', position: { x: 670, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-632-6', name: 'work-632', position: { x: 670, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-630',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '630',
    svgPath: 'M717 620V410H805V430H810V610H750V625H720V620H717Z',
    workplaces: [
      { uuid: 'work-630-1', name: 'work-630', position: { x: 720, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-630-2', name: 'work-630', position: { x: 720, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-630-3', name: 'work-630', position: { x: 720, y: 510, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-630-4', name: 'work-630', position: { x: 770, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-630-5', name: 'work-630', position: { x: 770, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-630-6', name: 'work-630', position: { x: 770, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-630-7', name: 'work-630', position: { x: 770, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-629',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '629',
    svgPath: 'M825 430V410H913V553H880V555H850V553H820V430H825Z',
    workplaces: [
      { uuid: 'work-629-1', name: 'work-629', position: { x: 820, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-629-2', name: 'work-629', position: { x: 820, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-629-3', name: 'work-629', position: { x: 870, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-629-4', name: 'work-629', position: { x: 870, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-629-5', name: 'work-629', position: { x: 870, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  ///
  {
    uuid: 'area-628',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '628',
    svgPath: 'M917 553V410H1005V430H1013V553H980V555H950V553H917Z',
    workplaces: [
      { uuid: 'work-628-1', name: 'work-628', position: { x: 920, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-628-2', name: 'work-628', position: { x: 920, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-628-3', name: 'work-628', position: { x: 920, y: 510, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-628-4', name: 'work-628', position: { x: 970, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-628-5', name: 'work-628', position: { x: 970, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-626',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '626',
    svgPath: 'M1025 430V410H1113V553H1080V555H1050V553H1017V430H1025Z',
    workplaces: [
      { uuid: 'work-626-1', name: 'work-626', position: { x: 1020, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-626-2', name: 'work-626', position: { x: 1020, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-626-3', name: 'work-626', position: { x: 1070, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-626-4', name: 'work-626', position: { x: 1070, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-626-5', name: 'work-626', position: { x: 1070, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-624',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '624',
    svgPath: 'M1117 553V410H1205V430H1220V530H1223V553H1180V555H1150V553H1117Z',
    workplaces: [
      { uuid: 'work-624-1', name: 'work-624', position: { x: 1120, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-624-2', name: 'work-624', position: { x: 1120, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-624-3', name: 'work-624', position: { x: 1120, y: 510, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-624-4', name: 'work-624', position: { x: 1180, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-624-5', name: 'work-624', position: { x: 1180, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-619',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '619',
    svgPath: 'M1625 430V410H1713V613H1680V615H1650V613H1625V610H1620V430H1625Z',
    workplaces: [
      { uuid: 'work-619-1', name: 'work-619', position: { x: 1620, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-619-2', name: 'work-619', position: { x: 1620, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-619-3', name: 'work-619', position: { x: 1620, y: 510, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-619-4', name: 'work-619', position: { x: 1620, y: 550, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-619-5', name: 'work-619', position: { x: 1670, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-619-6', name: 'work-619', position: { x: 1670, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-619-7', name: 'work-619', position: { x: 1670, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-619-8', name: 'work-619', position: { x: 1670, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-618',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '618',
    svgPath: 'M1717 613V410H1805V430H1813V610H1770V613H1760V615H1730V613H1717Z',
    workplaces: [
      { uuid: 'work-618-1', name: 'work-618', position: { x: 1720, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-618-2', name: 'work-618', position: { x: 1720, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-618-3', name: 'work-618', position: { x: 1720, y: 510, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-618-4', name: 'work-618', position: { x: 1770, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-618-5', name: 'work-618', position: { x: 1770, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-618-6', name: 'work-618', position: { x: 1770, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-618-7', name: 'work-618', position: { x: 1770, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-616',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '616',
    svgPath: 'M1825 430V410H1890V420H1893V613H1880V615H1850V613H1825V610H1817V430H1825Z',
    workplaces: [
      { uuid: 'work-616-1', name: 'work-616', position: { x: 1820, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-616-2', name: 'work-616', position: { x: 1820, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-616-3', name: 'work-616', position: { x: 1820, y: 510, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-616-4', name: 'work-616', position: { x: 1820, y: 550, angle: 90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-614',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '614',
    svgPath: 'M1920 420V410H1963V613H1940V615H1910V613H1897V420H1920Z',
    workplaces: [
      { uuid: 'work-614-1', name: 'work-614', position: { x: 1920, y: 430, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-614-2', name: 'work-614', position: { x: 1920, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-614-3', name: 'work-614', position: { x: 1920, y: 510, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-614-4', name: 'work-614', position: { x: 1920, y: 550, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-612',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '612',
    svgPath: 'M1967 523V410H2005V430H2070V440H2075V470H2070V523H1967Z',
    workplaces: [
      { uuid: 'work-612-1', name: 'work-612', position: { x: 1970, y: 430, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-612-2', name: 'work-612', position: { x: 1970, y: 470, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-612-3', name: 'work-612', position: { x: 2030, y: 470, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-611',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '611',
    svgPath: 'M1967 613V527H2070V613H2060V615H2030V613H1967Z',
    workplaces: [
      { uuid: 'work-611-1', name: 'work-611', position: { x: 1970, y: 530, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-611-2', name: 'work-611', position: { x: 1970, y: 570, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-611-3', name: 'work-611', position: { x: 2030, y: 530, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    uuid: 'area-605',
    type: AreaType.CABINET,
    position: { x: 20, y: 20 },
    size: { width: 280, height: 185 },
    name: '605',
    svgPath: 'M2605 430H2610V590H2590V600H2550V625H2520V620H2500V420H2520V410H2605V430Z',
    workplaces: [
      { uuid: 'work-605-1', name: 'work-605', position: { x: 2500, y: 450, angle: 90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-605-2', name: 'work-605', position: { x: 2570, y: 450, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-605-3', name: 'work-605', position: { x: 2570, y: 490, angle: -90 }, type: WorkplaceType.DEFAULT },
      { uuid: 'work-605-4', name: 'work-605', position: { x: 2570, y: 530, angle: -90 }, type: WorkplaceType.DEFAULT }
    ]
  },
  {
    name: 'PK-6-OP-1',
    uuid: 'area-pk-6-op-sp-1',
    type: AreaType.OPEN_SPACE,
    position: { x: 720, y: 20 },
    svgPath: '',
    workplaces: [
      {
        name: 'К618',
        uuid: 'pk6-op-1-1',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1260, y: 430, angle: -90 }
      },
      {
        name: 'К617',
        uuid: 'pk6-op-1-2',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1260, y: 470, angle: -90 }
      },
      {
        name: 'К616',
        uuid: 'pk6-op-1-3',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1260, y: 510, angle: -90 }
      },
      {
        name: 'К615',
        // textRotate: true,
        uuid: 'pk6-op-1-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1300, y: 430, angle: 90 }
      },
      {
        name: 'К614',
        uuid: 'pk6-op-1-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1300, y: 470, angle: 90 }
      },
      {
        name: 'К613',
        uuid: 'pk6-op-1-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1300, y: 510, angle: 90 }
      },
      ///
      {
        name: 'К612',
        uuid: 'pk6-op-1-1',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1370, y: 430, angle: -90 }
      },
      {
        name: 'К611',
        uuid: 'pk6-op-1-2',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1370, y: 470, angle: -90 }
      },
      {
        name: 'К610',
        uuid: 'pk6-op-1-3',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1370, y: 510, angle: -90 }
      },
      {
        name: 'К609',
        uuid: 'pk6-op-1-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1410, y: 430, angle: 90 }
      },
      {
        name: 'К608',
        uuid: 'pk6-op-1-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1410, y: 470, angle: 90 }
      },
      {
        name: 'К607',
        uuid: 'pk6-op-1-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1410, y: 510, angle: 90 }
      },

      {
        name: 'К606',
        uuid: 'pk6-op-1-1',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1480, y: 430, angle: -90 }
      },
      {
        name: 'К605',
        uuid: 'pk6-op-1-2',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1480, y: 470, angle: -90 }
      },
      {
        name: 'К604',
        uuid: 'pk6-op-1-3',
        textRotate: true,
        type: WorkplaceType.DEFAULT,
        position: { x: 1480, y: 510, angle: -90 }
      },
      {
        name: 'К603',
        uuid: 'pk6-op-1-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1520, y: 430, angle: 90 }
      },
      {
        name: 'К602',
        uuid: 'pk6-op-1-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1520, y: 470, angle: 90 }
      },
      {
        name: 'К601',
        uuid: 'pk6-op-1-4',
        type: WorkplaceType.DEFAULT,
        position: { x: 1520, y: 510, angle: 90 }
      }
    ]
  },
  {
    uuid: 'PS-6',
    type: AreaType.CABINET,
    name: 'Нефиксированное рабочее место',
    zones: [
      {
        position: { x: 2030, y: 210 },
        svgPath: 'M2080 230H2030V390H2200V230H2190V210H2080V230Z'
      },
      {
        position: { x: 2180, y: 410 },
        svgPath: 'M2489 410H2180V630H2489V532.222V410Z'
      },
      {
        position: { x: 800, y: 740 },
        svgPath: 'M963 740H800V830H960V790H963V740Z'
      },
      {
        position: { x: 1617, y: 703 },
        svgPath: 'M1760 830H1625V810H1617V763V703H1763V730H1760V830Z'
      },
      //Зоны переговорных комнат
      {
        position: { x: 1270, y: 670 },
        svgPath: 'M1300 670H1270V710H1300V670Z'
      },
      {
        position: { x: 1310, y: 670 },
        svgPath: 'M1340 670H1310V710H1340V670Z'
      },
      {
        position: { x: 1350, y: 670 },
        svgPath: 'M1380 670H1350V710H1380V670Z'
      },
      {
        position: { x: 1390, y: 670 },
        svgPath: 'M1420 670H1390V710H1420V670Z'
      },
      {
        position: { x: 2770, y: 500 },
        svgPath: 'M2810 500H2770V530H2810V500Z'
      },
      {
        position: { x: 2770, y: 540 },
        svgPath: 'M2810 540H2770V570H2810V540Z'
      },
      {
        position: { x: 2770, y: 580 },
        svgPath: 'M2810 580H2770V610H2810V580Z'
      }
    ]
  }
];
