import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import SupportInformation from './SupportInformation';
import SupportManagers from './SupportManagers';

const SupportLocation = ({ location, customer }) => {
  const formatPhoneNumber = phoneNumber => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
    }
    return phoneNumber;
  };
  return (
    <div className='support__location'>
      {location && (
        <div>
          <SupportInformation locationData={location} formatPhoneNumber={formatPhoneNumber} />
          <SupportManagers managers={location.communityManagers} formatPhoneNumber={formatPhoneNumber} />
        </div>
      )}
      {customer !== undefined && (
        <div className='support__btn-cont'>
          <Link className='btn btn--solid btn--sm support__requests-button' to='/tickets'>
            Отправить запрос команде Workki
          </Link>
        </div>
      )}
    </div>
  );
};

SupportLocation.propTypes = {
  location: PropTypes.object.isRequired
};

export default SupportLocation;
