import React from 'react';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

const SupportManagers = props => {
  const { managers, formatPhoneNumber } = props;

  return (
    <div className='support__managers'>
      <h2 className='title title--h2 support__title'>Комьюнити-менеджеры:</h2>
      <ul className='support__managers-list'>
        {managers.map(item => {
          return (
            <li key={item.first_name} className='support__managers-item'>
              <div className='manager'>
                <div className='manager__img-wrap'>
                  {item.photo ? (
                    <img className='manager__img' src={item.photo} alt='' width='46' height='46' />
                  ) : (
                    <ManageAccountsIcon sx={{ height: '46px', width: '46px', color: '#5555' }} />
                  )}
                </div>
                <div className='manager__information'>
                  <div className='manager__name'> {item.first_name} </div>
                  <a className='manager__email' href={`mailto: ${item.email}`}>
                    {' '}
                    {item.email}{' '}
                  </a>
                  <a className='manager__email' href={`tel: ${formatPhoneNumber(item.phone)}`}>
                    {' '}
                    {formatPhoneNumber(item.phone)}{' '}
                  </a>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SupportManagers;
