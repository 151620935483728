import React, { Component } from 'react';
import cn from 'classnames';
import Dropzone from 'react-dropzone';
import Container from './PrintFile.container';

const MAX_FILE_SIZE = 15000000; // 15MB
const VALID_TYPES = [
  'application/pdf',
  'text/plain',
  'application/msword',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/jpeg'
];

class PrintFile extends Component {
  state = {
    isEmpty: true,
    selectedFile: null,
    fileInputText:
      // eslint-disable-next-line max-len
      '<span class="print__file-text-bold">Перетащите в данную область</span> файл для печати <br />(pdf | word | excel | text | jpg) <br /> <span class="print__file-text-bold">не более 15MB</span>'
  };

  handleFileDrop = acceptedFiles => {
    const { showInfoToast } = this.props;
    const file = acceptedFiles[0];

    if (!file) {
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      showInfoToast('Размер файла не должен превышать 15MB');
      return;
    }

    if (!VALID_TYPES.includes(file.type)) {
      return;
    }
    this.setState({
      selectedFile: file,
      fileInputText: file.name,
      isEmpty: false
    });
  };

  handleSubmit = evt => {
    evt.preventDefault();
    const { sendToFile, reloadBalance } = this.props;
    const { selectedFile } = this.state;
    sendToFile(selectedFile)
      .then(() => {
        this.setState({
          isEmpty: true,
          selectedFile: null,
          fileInputText: '<span class="print__file-text-bold">Файл отправлен</span>'
        });
      })
      .catch(() => {})
      .finally(reloadBalance);
  };

  render() {
    const { fileInputText, isEmpty } = this.state;

    const fileLoadClasses = cn({
      'print__file-load': true,
      'is-empty': isEmpty
    });

    return (
      <div className='print__file print__section'>
        <h2 className='print__file-title title title--h2'>Отправить файл на печать:</h2>
        <form
          className='print__file-form'
          action='/'
          id='print-file-form'
          onSubmit={this.handleSubmit}
          autoComplete='off'
        >
          <Dropzone
            onDrop={this.handleFileDrop}
            multiple={false}
            accept={VALID_TYPES.join(',')}
            acceptClassName='print__file-load--accept'
            className={fileLoadClasses}
            inputProps={{
              className: 'print__file-load-input',
              id: 'print-file-load',
              name: 'print-file-load'
            }}
          >
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: fileInputText }}
              className='print__file-text'
            />
          </Dropzone>
          <button className='print__file-btn btn btn--solid btn--md' type='submit' disabled={isEmpty}>
            Печать
          </button>
        </form>
      </div>
    );
  }
}

export default Container(PrintFile);
