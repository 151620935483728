import React from 'react';
import PrimaryLayout from '../layouts/PrimaryLayout';
import ItServices from '../components/ItServices';
import PageLoader from '../components/PageLoader';

const ItServicesPage = ({ Customer, isLoading }) => (
  <PrimaryLayout>
    <main className='main'>
      <h1 className='page__title page__title_service-group'>ИТ-услуги</h1>
      {isLoading ? <PageLoader /> : <ItServices customer={Customer[0]} />}
    </main>
  </PrimaryLayout>
);

export default ItServicesPage;
