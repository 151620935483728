export default {
  current: [
    {
      name: 'Пакет печати 3: 100 листов ч/б, 25 цв (200 ч/б / 50 цв)',
      remainder: {
        black: '80',
        color: '45'
      },
      pin: 159193
    }
  ],
  all: [
    {
      id: 1,
      name: 'Пакет печати 1',
      description: '40 ч/б или 10 цветных',
      price: 400
    },
    {
      id: 2,
      name: 'Пакет печати 1',
      description: '90 ч/б или 20 цветных',
      price: 600
    },
    {
      id: 3,
      name: 'Пакет печати 1',
      description: '200 ч/б или 50 цветных',
      price: 900
    }
  ],
  history: {
    headers: ['Дата', 'Файл'],
    rows: [
      {
        date: '2018-05-15 10:34:07',
        name: 'cheif_editor_office-final.docx'
      },
      {
        date: '2018-04-30 10:34:07',
        name: '!!!confidential-nda.docx'
      },
      {
        date: '2018-12-09 10:34:07',
        name: '!!! РАСПЕЧАТАТЬ СРОЧНО ДИРЕКТОРУ...'
      },
      {
        date: '2018-11-23 10:34:07',
        name: 'proposal.pdf'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'proposal.pdf'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'document47283465crfvhdb.p...'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'byf743yg743ghv387hf37h75.pdf'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'РАСПЕЧАТАТЬ СРОЧНО'
      },
      {
        date: '2018-04-05 10:34:07',
        name: '74hf7548h8h5g857gv568gh.doc'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'скан паспорта'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'скан паспорта'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'РАСПЕЧАТАТЬ СРОЧНО'
      },
      {
        date: '2018-04-05 10:34:07',
        name: '74hf7548h8h5g857gv568gh.doc'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'скан паспорта'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'скан паспорта'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'РАСПЕЧАТАТЬ СРОЧНО'
      },
      {
        date: '2018-04-05 10:34:07',
        name: '74hf7548h8h5g857gv568gh.doc'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'скан паспорта'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'скан паспорта'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'РАСПЕЧАТАТЬ СРОЧНО'
      },
      {
        date: '2018-04-05 10:34:07',
        name: '74hf7548h8h5g857gv568gh.doc'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'скан паспорта'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'скан паспорта'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'скан паспорта'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'РАСПЕЧАТАТЬ СРОЧНО'
      },
      {
        date: '2018-04-05 10:34:07',
        name: '74hf7548h8h5g857gv568gh.doc'
      },
      {
        date: '2018-04-05 10:34:07',
        name: 'скан паспорта'
      }
    ]
  }
};
