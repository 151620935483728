import React from 'react';
import PrimaryLayout from '../layouts/PrimaryLayout';
import Print from '../components/Print/Print';
import PageLoader from '../components/PageLoader';

const PrintPage = ({ Customer, isLoading }) => {
  return (
    <PrimaryLayout>
      <main className='main'>
        <h1 className='page__title page__title_service-group'>Печать</h1>
        {isLoading ? <PageLoader /> : <Print customer={Customer[0]} />}
      </main>
    </PrimaryLayout>
  );
};

export default PrintPage;
