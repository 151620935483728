import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Edit, Delete } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import CustomSwitch from '../Switch';
import { colors } from '../../constants/styleConstants';

export const EmployeesTable = ({ employees, handleDelete, editEmployee, setEmployeeId }) => {
  const [checked, setChecked] = useState(true);
  //TODO доработаю когда появятся статусы у сотрудников - на данный момент все добавленные сотрудники считаются активными
  const handleSwitchChange = () => null;

  const StyledTable = styled(Table)({
    'borderCollapse': 'collapse',
    '& .MuiTableCell-root': {
      borderBottom: 'none'
    },
    '& .MuiTableHead-root .MuiTableCell-root': {
      borderBottom: `1px solid ${colors.workkiLightLight1}`,
      fontWeight: '600',
      color: colors.workkiDarkMiddle,
      padding: '16px 0 12px 0'
    },
    '& .phone-cell': {
      whiteSpace: 'nowrap'
    },
    '& .description-cell': {
      whiteSpace: 'normal',
      wordWrap: 'break-word'
    },
    '&. email': {
      whiteSpace: 'nowrap'
    }
  });
  const StyledTableHead = styled(TableHead)({
    color: colors.workkiDarkMiddle
  });

  const StyledTableContainer = styled(Paper)({
    boxShadow: 'none',
    borderRadius: '8px'
  });

  return (
    <StyledTableContainer>
      <StyledTable>
        <StyledTableHead>
          <TableRow>
            <TableCell />
            <TableCell>Имя</TableCell>
            <TableCell>E-mail</TableCell>
            <TableCell>Номер телефона</TableCell>
            <TableCell>Описание</TableCell>
            <TableCell />
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {employees
            .filter(employee => employee.is_active !== false)
            .map((employee, index) => (
              <TableRow key={index}>
                <TableCell>
                  <CustomSwitch
                    size={'small'}
                    spacing={'6px'}
                    checked={employee.is_active}
                    onChange={handleSwitchChange}
                  />
                </TableCell>
                <TableCell>{employee.name}</TableCell>
                <TableCell className='email'>{employee.email}</TableCell>
                <TableCell className='phone-cell'>+7{employee.mobile_phone}</TableCell>
                <TableCell className='description-cell'>{employee.description}</TableCell>
                <TableCell>
                  <IconButton
                    aria-label='edit'
                    onClick={() => {
                      setEmployeeId(employee.id);
                      editEmployee();
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton aria-label='delete' onClick={() => handleDelete(employee.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </StyledTable>
    </StyledTableContainer>
  );
};
