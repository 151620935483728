import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PrintHistoryItem from './PrintHistoryItem';
import NotHistory from '../NotHistory';
import { printerFiles } from '../../store/selectors/ServicesSelector';
import { getCustomerFiles } from '../../services/workki/CustomerActions';
import PrintHistoryTableHead from './PrintHistoryTableHead';

const mapStateToProps = state => ({
  auth: state.auth,
  files: printerFiles(state)
});

const mapDispatchToProps = dispatch => ({
  getHistory: () => dispatch(getCustomerFiles())
});

class PrintHistory extends Component {
  componentDidMount() {
    const { getHistory } = this.props;
    getHistory();
  }

  render() {
    const { files } = this.props;
    const text = 'Здесь появится ваша история печати';

    if (files.length === 0) {
      return (
        <section className='page__section std-table'>
          <NotHistory text={text} />
        </section>
      );
    }
    return (
      <div className='print__history print__section'>
        <h2 className='print__history-title title title--h2'>История печати</h2>
        <div className='print__history-list'>
          <div className='print__history-table-header-mobile-container'>
            <PrintHistoryTableHead />
          </div>
          <PrintHistoryItem rows={files.filter((elem, i) => i % 2 === 0)} />
          {files.length > 1 && <PrintHistoryItem rows={files.filter((elem, i) => i % 2 !== 0)} />}
        </div>
      </div>
    );
  }
}

PrintHistory.propTypes = {
  history: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintHistory);
