import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cn from 'classnames';
import CustomScrollbar from '../components/CustomScrollbar';
import { KEY_CODES } from '../constants';

const rootElemClassName = 'is-modal-window-open';

const rootElem = document.querySelector(`#root`);
const modalRoot = document.querySelector(`#modal-root`);

function ModalWindowWrapper(WrappedComponent) {
  class ModalWrapper extends Component {
    state = {
      isOpen: false
    };

    static getDerivedStateFromProps(props, state) {
      const { isModalWindowOpen } = props;
      const { isOpen } = state;
      if (!isOpen) {
        return { isOpen: isModalWindowOpen };
      }
      return null;
    }

    componentDidMount() {
      window.addEventListener('keyup', this.onKeyUp);
    }

    componentDidUpdate(prevProps) {
      const { isModalWindowOpen } = this.props;
      const { isOpen } = this.state;

      if (isModalWindowOpen === prevProps.isModalWindowOpen) {
        return;
      }

      if (isOpen === isModalWindowOpen) {
        return;
      }

      if (isModalWindowOpen) {
        this.open();
      } else {
        this.close();
      }
    }

    componentWillUnmount() {
      this.close();
      window.removeEventListener('keyup', this.onKeyUp);
    }

    close = () => {
      rootElem.classList.remove(rootElemClassName);
      const { closeModalWindow } = this.props;
      const { isOpen } = this.state;
      if (isOpen) {
        this.setState({ isOpen: false });
        closeModalWindow();
      }
    };

    open = () => {
      rootElem.classList.add(rootElemClassName);
      this.setState({
        isOpen: true
      });
    };

    onKeyUp = evt => {
      if (evt.keyCode !== KEY_CODES.ESC) {
        return;
      }

      this.close();
    };

    onModalWindowWrapperClick = evt => {
      evt.preventDefault();
      this.close();
    };

    onCloseBtnClick = () => {
      this.close();
    };

    onModalWindowClick = evt => {
      evt.stopPropagation();
    };

    render() {
      const { additionalClasses, modalWindowTitle, ...restProps } = this.props;
      const { isOpen } = this.state;
      const className = cn('modal-window', additionalClasses);

      if (!isOpen) {
        return null;
      }

      const ModalWrap = (
        <aside className='modal-window-wrapper' onDoubleClick={this.onModalWindowWrapperClick}>
          <CustomScrollbar>
            <div className={className} onDoubleClick={this.onModalWindowClick}>
              <div className='modal-window__header'>
                <h1 className='modal-window__title'>{modalWindowTitle}</h1>
                <button className='modal-window__close-btn' type='button' onClick={this.onCloseBtnClick}>
                  close
                </button>
              </div>
              <div className='modal-window__body'>
                <WrappedComponent {...restProps} />
              </div>
            </div>
          </CustomScrollbar>
        </aside>
      );

      return ReactDOM.createPortal(ModalWrap, modalRoot);
    }
  }

  ModalWrapper.displayName = `ModalWindowWrapper(${WrappedComponent.displayName ||
    WrappedComponent.name ||
    'Component'})`;

  return ModalWrapper;
}

ModalWindowWrapper.propTypes = {
  additionalClasses: PropTypes.arrayOf(PropTypes.string),
  isModalWindowOpen: PropTypes.bool.isRequired,
  modalWindowTitle: PropTypes.string.isRequired,
  closeModalWindow: PropTypes.func.isRequired
};

export default ModalWindowWrapper;
