import React from 'react';

const PrintHistoryTableHead = () => {
  return (
    <thead className='print__history-table-head'>
      <tr className='print__history-table-row print__history-table-row--head'>
        <th className='print__history-table-cell print__history-table-cell--head'>Файл</th>
        <th className='print__history-table-cell print__history-table-cell--head'>Дата</th>
      </tr>
    </thead>
  );
};

export default PrintHistoryTableHead;
