import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CommunityNavigation from './CommunityNavigation';
import Search from '../Search';
import CommunityList from './CommunityList';
import { getPublicProfileList } from '../../services/workki/PublicProfileActions';
import { publicProfilesSelector } from '../../store/selectors/DefaultSelectors';
import ContentLoader from '../ContentLoader';
import * as actions from '../../store/actions';
import requestStatusSelector from '../../store/selectors/requestStatusSelector';
import arrow from '../../../media/icons/arrow.svg';

const mapStateToProps = state => ({
  communityPublicProfiles: publicProfilesSelector(state),
  requestStatus: requestStatusSelector(actions.requestPublicProfileList)(state)
});

const mapDispatchToProps = dispatch => ({
  loadPublicProfiles: () => dispatch(getPublicProfileList())
});

const Loader = () => (
  <div className='community__loader'>
    <ContentLoader />
  </div>
);
class Community extends Component {
  state = {
    activeLetter: null,
    activeQuery: null,
    navigationOpen: false
  };

  componentDidMount() {
    this.props.loadPublicProfiles();
  }

  setLetter = (letter = null) => {
    const { requestStatus } = this.props;
    if (requestStatus.isPending) {
      return;
    }

    this.setState({ activeLetter: letter });
  };

  setQuery = (query = null) => {
    const { requestStatus } = this.props;
    if (requestStatus.isPending) {
      return;
    }

    this.setState({ activeQuery: query });
  };

  renderList = () => {
    const { activeLetter, activeQuery } = this.state;
    const { communityPublicProfiles } = this.props;

    if (!communityPublicProfiles.length) {
      return (
        <div className='profile-social profile-social--with-warning'>
          <div className='profile-social__warning'>
            Просмотр профилей наших резидентов доступен для зарегистрированных пользователей, прошедших процедуру
            проверки и арендовавших хотя-бы одно рабочее место.Вы можете заполнить информацию о своем бизнесе{' '}
            <Link className='event__link link' to='/profile'>
              в профиле пользователя
            </Link>{' '}
            на вкладке "Социальный профиль". (Вкладка "Социальный профиль" доступна после подтверждения основного
            логина.)
          </div>
        </div>
      );
    }

    let communitiesToShow = communityPublicProfiles;

    if (activeLetter !== null) {
      const activeLetterRegex = new RegExp(`^[${activeLetter}]`, 'i');
      communitiesToShow = communityPublicProfiles.filter(item => activeLetterRegex.test(item.name));
    }

    if (activeQuery !== null) {
      communitiesToShow = communityPublicProfiles.filter(item =>
        item.name.toLowerCase().includes(activeQuery.toLowerCase())
      );
    }

    return (
      <CommunityList activeLetter={activeLetter} activeQuery={activeQuery} communitiesToShow={communitiesToShow} />
    );
  };

  setNavigationStatus = () => {
    this.setState({
      navigationOpen: !this.state.navigationOpen
    });
  };

  render() {
    const { activeLetter, activeQuery } = this.state;
    const { requestStatus } = this.props;

    return (
      <div className='community'>
        <div className='community__header'>
          <h1 className='page__title community__title'>Резиденты</h1>
          {Array.isArray(this.props.communityPublicProfiles) && this.props.communityPublicProfiles.length > 0 && (
            <div className='community__reverseContainer'>
              <div className='community__navigation-container'>
                <div className='community__navigation-btn-container'>
                  <button onClick={this.setNavigationStatus} className='community__navigation-btn-open-status'>
                    Найти по алфавиту
                    <img
                      className={`community__arrow ${this.state.navigationOpen && 'community__arrow_open'}`}
                      src={arrow}
                      alt=''
                    />
                  </button>
                  {this.state.activeLetter !== null && (
                    <button onClick={() => this.setLetter(null)} className='community__navigation-reset-btn'>
                      Сбросить фильтр
                    </button>
                  )}
                </div>
                <div className={`community__status ${this.state.navigationOpen && 'community__status_open'}`}>
                  <CommunityNavigation
                    activeQuery={activeQuery}
                    activeLetter={activeLetter}
                    setQuery={this.setQuery}
                    setLetter={this.setLetter}
                  />
                </div>
              </div>
              <Search
                activeQuery={activeQuery}
                setQuery={this.setQuery}
                onOpen={this.setLetter}
                resetLetterFilter={() => this.setLetter(null)}
              />
            </div>
          )}
        </div>
        {requestStatus.isPending ? <Loader /> : this.renderList()}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Community);
