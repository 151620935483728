import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isArray } from 'lodash';
import printData from './printData';
import PrintCurrentPackage from './PrintCurrentPackage';
import PrintPackages from './PrintPackages';
import PrintHistory from './PrintHistory';
import PrintFile from './PrintFile';
import { getBalancePrinter } from '../../services/workki/CustomerActions';
import { customerSelector } from '../../store/selectors/DefaultSelectors';
import { getActiveServiceRequests } from '../../services/workki/ServicesActions';

class Print extends Component {
  state = {
    balance: 0
  };

  componentDidMount() {
    const { customer, dispatchGetActiveServiceRequests } = this.props;
    if (customer) {
      this.reloadBalancePages();
      dispatchGetActiveServiceRequests();
    }
  }

  componentDidUpdate(prevProps) {
    const { customer, dispatchGetActiveServiceRequests } = this.props;
    if (!prevProps.customer && customer) {
      this.reloadBalancePages();
      dispatchGetActiveServiceRequests();
    }
  }

  reloadBalancePages = async () => {
    const { dispatchGetBalancePrinter } = this.props;
    dispatchGetBalancePrinter()
      .then(response => {
        if (response.data && isArray(response.data)) {
          this.setState({ balance: response.data[0].balance });
        }
      })
      .catch(() => {});
  };

  render() {
    const { current, history } = printData;
    const { balance } = this.state;
    const { customer } = this.props;
    return (
      <div className='print'>
        <PrintFile balance={balance} customer={customer} reloadBalance={this.reloadBalancePages} />
        <PrintCurrentPackage balance={balance} customer={customer} current={current} />
        <PrintPackages reloadBalance={this.reloadBalancePages} />
        <PrintHistory history={history} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customer: customerSelector(state)
});

const mapDispatchToProps = dispatch => ({
  dispatchGetBalancePrinter: () => dispatch(getBalancePrinter()),
  dispatchGetActiveServiceRequests: () => dispatch(getActiveServiceRequests())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Print);
