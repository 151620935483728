import React, { useEffect } from 'react';
import PrimaryLayout from '../layouts/PrimaryLayout';
import Support from '../components/Support';
import { useDispatch, useSelector } from 'react-redux';
import { officeSelector } from '../store/selectors/DefaultSelectors';
import { getOffice } from '../services/workki/TypesActions';

const SupportPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOffice());
  }, [dispatch]);

  const offices = useSelector(officeSelector);
  return (
    <PrimaryLayout>
      <main className='main'>
        <h1 className='page__title support__title-main'>Поддержка</h1>
        {offices && <Support offices={offices} />}
      </main>
    </PrimaryLayout>
  );
};

export default SupportPage;
