import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const LocationPickerMobile = ({ setLocation, additionalClasses, locations, currentLocation }) => {
  const onLocationBtnClick = location => evt => {
    evt.preventDefault();
    setLocation(location);
  };

  const [visibleList, setVisibleList] = useState(false);

  const _setVisibleList = () => {
    if (window.innerWidth < 748) setVisibleList(!visibleList);
  };

  const className = classNames('location-picker-mobile', additionalClasses);

  return (
    <>
      {locations.length > 0 && (
        <div
          onClick={_setVisibleList}
          className={`${className} ${visibleList && 'location-picker-mobile__rotate-icon'}`}
        >
          <p>{currentLocation.t_name}</p>
          <ul className={`location-picker-mobile__list ${visibleList && 'location-picker-mobile__list_visible'}`}>
            {locations.map(item => {
              const locationMenuItemClass = classNames({
                'location-picker-mobile__item': true,
                'active': currentLocation.id === item.id
              });

              return (
                <li className={locationMenuItemClass} key={item.id}>
                  <button
                    type='button'
                    onClick={onLocationBtnClick(item)}
                    name={item.t_name || item.id}
                    className='location-picker-mobile__btn btn'
                  >
                    {item.t_name}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </>
  );
};

LocationPickerMobile.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  setLocation: PropTypes.func.isRequired,
  additionalClasses: PropTypes.arrayOf(PropTypes.string)
};

export default LocationPickerMobile;
