import React, { Component } from 'react';
import NumberSpinner from '../NumberSpinner';
import moment from 'moment-timezone';

const MIN_NUMBER_OF_PACKAGES = 1;

class PrintProductCard extends Component {
  state = {
    numberOfPackages: MIN_NUMBER_OF_PACKAGES
  };

  get contractsForSelect() {
    const { contract } = this.props;
    if (!contract) return '';

    return {
      id: contract.id,
      value: contract.id,
      number: contract.id,
      start_at: contract.date,
      type: contract.type,
      label: `№ ${contract.number} от ${moment(contract.date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY')}`
    };
  }

  setNumber = number => {
    this.setState({
      numberOfPackages: number
    });
  };

  getSelectedContract = () => {
    if (this.state.contract) {
      return this.state.contract;
    } else {
      const { value, type } = this.contractsForSelect;

      return {
        contract_id: value,
        type: type
      };
    }
  };

  handleSubmit = (print, evt) => {
    const { numberOfPackages } = this.state;
    const { buyPackage, reloadBalance, isLoading } = this.props;
    evt.preventDefault();

    if (isLoading) {
      return;
    }
    const contract = this.getSelectedContract();

    buyPackage(print.id, numberOfPackages, print.servicePrices[0].id, reloadBalance, contract);
  };

  setContract = e => {
    if (!e.target || !e.target.value) return;
    const { contract } = this.props;
    if (!contract) return;

    const id = Number(e.target.value);

    if (contract.contract.id === id) {
      this.setState({ contract: { contract_id: id, type: contract.type } });
    } else {
      this.setState({ contract: { contract_id: id, type: contract.type } });
    }
  };

  render() {
    const { numberOfPackages } = this.state;
    const { printPackage, isLoading, office } = this.props;

    return (
      <div className='print-product-card'>
        <form
          onSubmit={this.handleSubmit.bind(this, printPackage)}
          id={printPackage.id}
          className='print-product-card__form'
          name={printPackage.name}
          method='POST'
          autoComplete='off'
        >
          <h2 className='print-product-card__title title title--h2'>{printPackage.name}</h2>
          {office ? <h2 className='print-product-card__title title title--h2'>{office.t_name}</h2> : null}
          <div className='print-product-card__contract'> Договор {this.contractsForSelect.label}</div>
          <div className='print-product-card__description'>{printPackage.description}</div>
          <div className='print-product-card__wrap-counter'>
            кол-во пакетов:
            <div className='print-product-card__counter'>
              <NumberSpinner minNumber={MIN_NUMBER_OF_PACKAGES} number={numberOfPackages} setNumber={this.setNumber} />
            </div>
          </div>
          <div className='print-product-card__action'>
            <div className='print-product-card__price'>
              {`${printPackage.servicePrices[0].price * numberOfPackages} ₽`}
            </div>
            <button className='print-product-card__submit btn btn--md btn--solid' type='submit' disabled={isLoading}>
              Купить
            </button>
          </div>
        </form>
      </div>
    );
  }
}

PrintProductCard.propTypes = {
  // id: PropTypes.number.isRequired,
  // name: PropTypes.string.isRequired,
  // description: PropTypes.string.isRequired,
  // price: PropTypes.number.isRequired
};

export default PrintProductCard;
