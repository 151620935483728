import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import { customerSelector } from '../store/selectors/DefaultSelectors';
import requestStatusSelector from '../store/selectors/requestStatusSelector';
import * as actions from '../store/actions';

const mapStateToProps = state => {
  const customer = customerSelector(state);
  const requestAuthStatus = requestStatusSelector(actions.requestLogin)(state);

  return {
    isAuthenticated: state.isAuthenticated,
    id: customer.id,
    gotData: state.ui && !!state.ui.customerCurrent,
    isPending: requestAuthStatus.isPending || state.ui.customerCurrent.isFetching
  };
};

class PersonalIdentifier extends PureComponent {
  render() {
    const { isAuthenticated, id, isPending, gotData } = this.props;
    if (!gotData || !isAuthenticated) {
      return null;
    }
    return (
      <div className='personal_identifier'>
        Ваш ID пользователя:
        {isPending ? (
          <span className='personal_identifier__spinner'>
            <Loader type='ThreeDots' color='#f15a4f' height='20' width='32' />
          </span>
        ) : (
          <span className='personal_identifier__identifier'>{id}</span>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps)(PersonalIdentifier);
