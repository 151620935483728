import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import LocationPicker from '../LocationPicker';
import SupportLocation from './SupportLocation';
import { customerSelector } from '../../store/selectors/DefaultSelectors';
import LocationPickerMobile from '../LocationPickerMobile';
import { useWindowSize } from '../../utils/getWindowSize';
import PersonalIdentifier from '../PersonalIdentifier';

const Support = ({ offices }) => {
  const activeOffices = offices.filter(office => office.active === 1 && office.communityManagers.length > 0);

  const [currentLocation, setCurrentLocation] = useState(activeOffices[0]);
  useEffect(() => {
    if (offices && offices.length > 0) {
      setCurrentLocation(activeOffices[0]);
    }
  }, [offices]);

  const [width] = useWindowSize();

  const customer = useSelector(customerSelector);

  if (customer === undefined) {
    return null;
  }

  return (
    <>
      {activeOffices && (
        <section className='page__section support'>
          <div className='support__location-picker'>
            {width > 747 ? (
              <LocationPicker
                currentLocation={currentLocation}
                locations={activeOffices}
                setLocation={setCurrentLocation}
                additionalClasses={['location-picker--buttons']}
              />
            ) : (
              <LocationPickerMobile
                currentLocation={currentLocation}
                locations={activeOffices}
                setLocation={setCurrentLocation}
                additionalClasses={['location-picker--buttons']}
              />
            )}
          </div>
          <SupportLocation location={currentLocation} customer={customer} />
          <div className='support__personal-id'>
            <PersonalIdentifier />
          </div>
        </section>
      )}
    </>
  );
};

export default Support;
