import { LOADING_STATUSES } from '../constants';
import { hasActualToken } from '../utils/token';
import * as actions from './actions';

const requestStatuses = Object.keys(actions).reduce((acc, actionKey) => {
  const { type } = actions[actionKey];
  const matches = /(.*):REQUEST/.exec(type);

  if (!matches) {
    return acc;
  }

  const [, requestName] = matches;
  acc[requestName] = LOADING_STATUSES.INIT;
  return acc;
}, {});

const requestErrors = Object.keys(requestStatuses).reduce((acc, req) => ({ ...acc, [req]: null }), {});
const requestPages = Object.keys(requestStatuses).reduce((acc, req) => ({ ...acc, [req]: null }), {});

export default {
  isAuthenticated: hasActualToken(),
  isModalOpen: false,
  settings: {
    isPublicCustomerProfile: false
  },
  location: {
    currentLocationId: null,
    locationsWithLoadedDataIds: []
  },
  transactions: {
    allIds: [],
    byId: {}
  },
  errors: {
    auth: '',
    guestCreate: '',
    parkingCreate: '',
    itServiceTaskCreate: '',
    serviceRequestCreate: '',
    createTicket: '',
    createTicketComment: ''
  },
  requestStatuses,
  requestErrors,
  requestPages
};
