import { useEffect, useRef } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import throttle from 'lodash.throttle';
import { clamp } from '.././serviceUtils/clamp';

export const useHoverSlider = options => {
  const [sliderRef, slider] = useKeenSlider({
    initial: 0,
    disabled: false,
    loop: true,
    defaultAnimation: {
      duration: 1
    },
    created(slider) {
      slider.container.addEventListener(
        'mousemove',
        throttle(async e => {
          const mouseLocation = e.pageX;
          const boundingRect = slider.container.getBoundingClientRect();
          const { x: leftBorder, right: rightBorder } = boundingRect;
          const slideCount = options.slides || slider.slides.length;

          const relativeMousePosition = clamp(mouseLocation - leftBorder, 0, rightBorder - leftBorder);
          const slideWidth = clamp((rightBorder - leftBorder) / slideCount, 1); // prevent division by zero on next line
          const nextSlideIndex = clamp(Math.floor(relativeMousePosition / slideWidth), 0, slideCount - 1);

          if (slider.track.details.rel !== nextSlideIndex) {
            slider.moveToIdx(nextSlideIndex);
          }
        }, 150)
      );
    },
    ...options
  });

  return { sliderRef, slider };
};
