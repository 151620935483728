import React from 'react';
import { styled } from '@mui/material/styles';
import { colors } from '../constants/styleConstants';
import { Button } from '@mui/material';
import { ArrowBack } from '../icons/core/ArrowBack';

const StyledButton = styled(Button)(
  ({ theme, disabled, variant, filledColor, iconType, spacing, outlinedType, fontSizeOverride }) => ({
    'borderRadius': '8px',
    'marginRight': spacing ? spacing : '6px',
    '&.Mui-disabled': {
      color: variant === 'filled' ? colors.workkiLightWhite : colors.workkiLightLine
    },
    'border':
      variant === 'outlined'
        ? `1px solid ${disabled ? colors.workkiLightLine : outlinedType ? colors.workkiRedFill : colors.workkiDarkMain}`
        : 'transparent',
    'backgroundColor':
      variant === 'filled' && filledColor === 'red'
        ? disabled
          ? colors.workkiRedLine
          : colors.workkiRedFill
        : filledColor === 'black'
        ? disabled
          ? colors.workkiLightLine
          : colors.workkiDarkFill
        : 'transparent',
    'color': `${
      variant === 'filled'
        ? colors.workkiLightWhite
        : variant === 'outlined' && outlinedType
        ? colors.workkiRedFill
        : colors.workkiDarkMain
    }`,
    '&:hover': {
      border:
        variant === 'outlined'
          ? outlinedType
            ? `1px solid ${colors.workkiRedLine}`
            : `1px solid ${colors.workkiDarkMiddle}`
          : 'none',
      backgroundColor:
        variant === 'filled' && filledColor === 'red'
          ? colors.workkiRedMain
          : filledColor === 'black'
          ? colors.workkiDarkMain
          : 'transparent'
    },
    'textTransform': 'none',
    'padding': '12px',
    'fontSize': fontSizeOverride ? fontSizeOverride : '16px',
    'cursor': 'pointer',
    'height': '48px',
    '& > span': {
      marginRight: iconType ? '8px' : 0
    }
  })
);

export const StepButton = ({
  handleAction,
  title,
  iconType,
  disabled,
  type,
  filledType,
  spacing,
  iconColor,
  outlinedType,
  fontSizeOverride
}) => {
  return (
    <StyledButton
      filledColor={filledType}
      variant={type}
      outlinedType={outlinedType}
      fontSizeOverride={fontSizeOverride}
      disabled={disabled}
      onClick={handleAction}
      iconType={iconType}
      spacing={spacing}
    >
      <span>{title}</span>
      {iconType && (
        <ArrowBack
          defaultColor={iconColor ? iconColor : disabled ? colors.workkiLightLine : colors.workkiDarkMain}
          spacing={'7px'}
          hoverColor={colors.workkiDarkFill}
          rotation={'180deg'}
        />
      )}
    </StyledButton>
  );
};
