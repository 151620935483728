import React, { useState } from 'react';
import { colors, fontFamilyBase, screenRessolutions } from '../../shared/ui/constants/styleConstants';
import { useResize } from '../../shared/utils/hooks/useResize';
import styled from 'styled-components';
import CardShell from '../../shared/ui/components/Profile/CardShell';
import { ArrowBack } from '../../shared/ui/icons/core/ArrowBack';
import { useHistory } from 'react-router-dom';
import { Hint } from '../../shared/ui/icons/core/Hint';
import PriceInfoDialog from '../../widgets/DashboardWidgets/PriceInfoDialog';

const UserInfoSectionsBox = styled.div`
  margin: 24px 0 0 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 28px;
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-width: ${screenRessolutions.desktop}px) {
    margin-top: 36px;
  }
`;

const UserInfoBox = styled.div`
  margin: 0;
  padding: 0;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-template-areas:
    'id id'
    'amount printer';
  box-sizing: border-box;
  gap: 8px 8px;
`;

const UserIdBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;

  & > p {
    margin: 0;
    padding: 0;
    display: flex;
    font-family: ${fontFamilyBase};
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    color: #24272a;

    &:last-of-type {
      font-size: 18px;
      line-height: 20px;
      letter-spacing: -0.01em;
    }
  }
`;

const UserGeneralInfoBox = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 96px;
  grid-area: amount;

  & > h4 {
    margin: 0;
    padding: 0;
    display: flex;
    gap: 4px;
    font-family: ${fontFamilyBase};
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: ${colors.workkiDarkSecondary};
  }

  & > p {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    font-family: ${fontFamilyBase};
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    color: #24272a;

    & > span {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: ${colors.workkiDarkSecondary};
    }
  }
`;

const ResidentsAndSupportSectionsBox = styled.ul`
  margin: 28px 0 0 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-template-rows: repeat(2, 54px);
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
`;

const ResidentsAndSupportSectionsText = styled.h4`
  margin: 0;
  padding: 0;
  display: flex;
  font-family: ${fontFamilyBase};
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #24272a;
  align-self: center;
`;

const DashboardUserInfoSections = ({ account, customer }) => {
  const [isTablet, setIsTablet] = useState(window.innerWidth <= screenRessolutions.laptop);
  const handleChangeScreenState = () => {
    const isScreenTablet = window.innerWidth <= screenRessolutions.laptop;
    if (isScreenTablet !== isTablet) setIsTablet(isScreenTablet);
  };
  useResize(handleChangeScreenState, 0);

  const getAmount = string => {
    let num = parseFloat(string);
    let numStr = Number.isInteger(num)
      ? num.toLocaleString('ru-RU')
      : num.toLocaleString('ru-RU', { minimumFractionDigits: 2 });
    numStr = numStr.replace(/[.,]00$/, '');
    numStr = numStr.replace('.', ',').replace(/(.*),(.*)/, '$1, $2');
    let result = numStr + ' ₽';
    return result;
  };

  const [dialogPrintState, setDialogPrintState] = useState(false);
  const handleCloseModal = () => setDialogPrintState(false);

  const history = useHistory();

  const cardData = [
    {
      key: 'id',
      padding: '14px 16px',
      content: (
        <UserIdBox>
          <p>ID аккаунта:</p>
          <p>{customer.id}</p>
        </UserIdBox>
      )
    },
    {
      key: 'amount',
      padding: '10px 12px 12px',
      content: (
        <UserGeneralInfoBox>
          <h4>Состояние счёта</h4>
          <p>{getAmount(account.amount)}</p>
        </UserGeneralInfoBox>
      )
    },
    {
      key: 'printer',
      padding: '10px 12px 12px',
      content: (
        <UserGeneralInfoBox>
          <h4>
            Баланс печати <Hint onClick={() => setDialogPrintState(true)} />
          </h4>
          {customer && customer.printer && (
            <p>
              {customer.printer.balance} ч/б
              <span>или {Math.floor(customer.printer.balance / 4)} цветных</span>
            </p>
          )}
        </UserGeneralInfoBox>
      )
    }
  ];

  const residentAndSupportData = [
    {
      text: 'Резиденты',
      onClick: () => history.push('/community')
    },
    {
      text: 'Поддержка',
      onClick: () => history.push('/support')
    }
  ];

  return (
    <UserInfoSectionsBox>
      <UserInfoBox>
        {cardData.map(({ key, padding, content }) => (
          <CardShell key={key} padding={padding} gridArea={key}>
            {content}
          </CardShell>
        ))}
      </UserInfoBox>
      {isTablet && (
        <ResidentsAndSupportSectionsBox>
          {residentAndSupportData.map(({ text, onClick }, index) => (
            <CardShell key={index} padding='12px 12px 14px 16px' flexDirection={'row'}>
              <ResidentsAndSupportSectionsText>{text}</ResidentsAndSupportSectionsText>
              <ArrowBack
                rotation='180deg'
                spacing='0'
                defaultColor={colors.workkiDarkSecondary}
                width='28px'
                height='28px'
                backgroundColor={colors.workkiLightLight1}
                borderRadius='4px'
                onClick={onClick}
              />
            </CardShell>
          ))}
        </ResidentsAndSupportSectionsBox>
      )}
      {dialogPrintState && <PriceInfoDialog infoType='print' handleClose={handleCloseModal} />}
    </UserInfoSectionsBox>
  );
};

export default DashboardUserInfoSections;
