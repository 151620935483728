import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import NotHistory from '../NotHistory';
import ContentLoader from '../ContentLoader';
import { itServicesTasksSelector } from '../../store/selectors/ServicesSelector';
import ItServicesTicketsList from './ItServicesTaskList';
import * as actions from '../../store/actions';
import requestStatusSelector from '../../store/selectors/requestStatusSelector';

const TEXT_ON_EMPTY_LIST = 'Здесь появится ваша история заявок на ИТ-услуги';
const TEXT_ON_ERROR_LOADING_LIST =
  'Ошибка загрузки истории заявок на ИТ-услуги. Попробуйте зайти позже или обратитесь к комьюнити менеджеру';

const ItServiceTickets = ({ customer }) => {
  const list = useSelector(itServicesTasksSelector);
  const taskGetListStatus = useSelector(requestStatusSelector(actions.requestItServiceTaskList));

  if (taskGetListStatus.isInit || taskGetListStatus.isPending) {
    return (
      <section className='page__section std-table'>
        <ContentLoader />
      </section>
    );
  }

  if (taskGetListStatus.isFailure) {
    return (
      <section className='page__section std-table'>
        <NotHistory text={TEXT_ON_ERROR_LOADING_LIST} />
      </section>
    );
  }

  if (list.length === 0) {
    return (
      <section className='page__section std-table'>
        <NotHistory text={TEXT_ON_EMPTY_LIST} />
      </section>
    );
  }

  return (
    <section className='page__section std-table'>
      <div className='std-table__title'>История заявок</div>
      <div className='std-table__blur-container'>
        <div className='std-table__overflow-table'>
          <table className='std-table__table' cellSpacing='0'>
            <thead>
              <tr className='std-table__table-head'>
                <td className='std-table__cell std-table__cell--head'>Рабочее место</td>
                <td className='std-table__cell std-table__cell--head'>Тип заявки</td>
                <td className='std-table__cell std-table__cell--head'>Дата заявки</td>
                <td className='std-table__cell std-table__cell--head'>Начало</td>
              </tr>
            </thead>
            <tbody>
              <ItServicesTicketsList list={list} />
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

ItServiceTickets.propTypes = {
  customer: PropTypes.object
};

export default ItServiceTickets;
