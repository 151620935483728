import React, { useState } from 'react';
import { TextField, MenuItem, FormControl } from '@mui/material';
import { styled } from '@mui/system';
import { SelectArrow } from '../icons/core/SelectArrow';
import { IconComponent } from './Field/IconComponent';

const colors = {
  workkiDarkMain: '#24272a',
  workkiDarkFill: '#383c40',
  workkiDarkSecondary: '#575b60',
  workkiDarkMiddle: '#8f9295',
  workkiLightLine: '#c8c9ca',
  workkiLightLight2: '#e9eaea',
  workkiLightLight1: '#f4f4f4',
  workkiLightLight05: '#fafafa',
  workkiLightWhite: '#fff',
  workkiRedMain: '#ba393a',
  workkiRedFill: '#db4344',
  workkiRedLine: '#f1b4b4',
  workkiRedLight: '#fdf6f6',
  workkiGreenMain: '#008d64',
  workkiGreenFill: '#00a676',
  workkiGreenLine: '#99dbc8',
  workkiGreenLight: '#f2fbf8',
  workkiBlueMain: '#005f7a',
  workkiBlueFill: '#00708f',
  workkiBlueLine: '#00708f',
  workkiBlueLight: '#f2f8f9'
};

const StyledFormControl = styled(FormControl)({
  'marginBottom': 16,
  '&.MuiFormControl-fullWidth:focus': {
    borderRadius: 8
  }
});

const StyledTextField = styled(TextField)({
  '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input': {
    'paddingRight': 0,
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input.Mui-disabled': {
    '-webkit-text-fill-color': colors.workkiDarkMain, // Устанавливает цвет текста для WebKit-браузеров
    'color': colors.workkiDarkMain // Для других браузеров
  },
  '& .MuiInputBase-root.Mui-disabled': {
    cursor: 'not-allowed',
    backgroundColor: colors.workkiLightLight1,
    color: `${colors.workkiDarkMain} !important`
  },
  '& .MuiInputBase-root': {
    'backgroundColor': colors.workkiLightLight1,
    '&.Mui-focused': {
      border: `1px ${colors.workkiLightLine} solid !important`,
      borderRadius: 8,
      backgroundColor: colors.workkiLightLight1
    },
    '&.Mui-disabled': {
      cursor: 'not-allowed',
      backgroundColor: colors.workkiLightLight1
    },
    '&:hover': {
      border: `1px ${colors.workkiLightLine} solid !important`,
      backgroundColor: colors.workkiLightLight1
    },
    '&.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
      'borderRadius': 8,
      'paddingRight': 0,
      'cursor': 'pointer',
      '&::before': {
        border: 'transparent 1px solid',
        borderRadius: 8
      },
      '&::after': {
        border: '1px solid transparent',
        borderRadius: 8
      }
    }
  },
  '& .MuiInputLabel-root': {
    'paddingLeft': 0,
    '&.Mui-focused': {
      color: `${colors.workkiDarkMiddle} !important`
    },
    '&.Mui-error': {
      color: `${colors.workkiDarkMiddle} !important`
    }
  }
});

const StyledMenuItem = styled(MenuItem)({
  '&:hover': {
    backgroundColor: colors.workkiLightLight2
  }
});

export const StyledSelectArrow = styled(SelectArrow)({
  '&:hover': {
    cursor: 'pointer'
  },
  'transition': 'transform 0.3s ease',
  'transform': 'rotate(0deg)',
  '&.rotate': {
    transform: 'rotate(180deg)'
  }
});

export const CustomSelectField = ({
  id,
  label,
  name,
  variant,
  value,
  onFocus,
  onBlur,
  onChange,
  placeholder,
  autoFocus,
  options,
  isFocused,
  isDisabled
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <StyledFormControl fullWidth={true}>
      <StyledTextField
        select
        name={name}
        id={id}
        label={label}
        variant={variant}
        value={value}
        disabled={isDisabled}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        autoFocus={autoFocus}
        SelectProps={{
          open: menuOpen,
          onClose: handleMenuClose,
          onOpen: handleMenuOpen,
          IconComponent: () =>
            !isDisabled ? (
              <StyledSelectArrow onClick={handleMenuClose} rotation={isFocused && menuOpen ? '180deg' : '0deg'} />
            ) : (
              <IconComponent
                type='disabled'
                spacing={'16px'}
                handleAction={() => null}
                defaultColor={colors.workkiDarkMiddle}
              />
            )
        }}
      >
        {options.map(option => (
          <StyledMenuItem key={option.value} value={option.value}>
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledTextField>
    </StyledFormControl>
  );
};
