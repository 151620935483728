import React from 'react';
import SupportInformationContacts from './SupportInformationContacts';
import SupportInformationLinks from './SupportInformationLinks';

const SupportInformation = ({ locationData, formatPhoneNumber }) => {
  return (
    <div className='support__information'>
      <SupportInformationContacts locationData={locationData} formatPhoneNumber={formatPhoneNumber} />
      <SupportInformationLinks />
    </div>
  );
};

export default SupportInformation;
