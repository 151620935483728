import React, { useState } from 'react';
import { TextField, FormControl, MenuItem } from '@mui/material';
import { SelectArrow } from '../icons/core/SelectArrow';
import { styled } from '@mui/system';

const colors = {
  workkiDarkMain: '#24272a',
  workkiDarkFill: '#383c40',
  workkiDarkSecondary: '#575b60',
  workkiDarkMiddle: '#8f9295',
  workkiLightLine: '#c8c9ca',
  workkiLightLight2: '#e9eaea',
  workkiLightLight1: '#f4f4f4',
  workkiLightLight05: '#fafafa',
  workkiLightWhite: '#fff',
  workkiRedMain: '#ba393a',
  workkiRedFill: '#db4344',
  workkiRedLine: '#f1b4b4',
  workkiRedLight: '#fdf6f6',
  workkiGreenMain: '#008d64',
  workkiGreenFill: '#00a676',
  workkiGreenLine: '#99dbc8',
  workkiGreenLight: '#f2fbf8',
  workkiBlueMain: '#005f7a',
  workkiBlueFill: '#00708f',
  workkiBlueLine: '#00708f',
  workkiBlueLight: '#f2f8f9'
};

const DropdownWrapper = styled('div')({
  // minWidth: '122px'
});

const StyledFormControl = styled(FormControl)({
  'marginBottom': 16,
  '&.MuiFormControl-fullWidth:focus': {
    borderRadius: 8
  }
});

const StyledTextField = styled(TextField)({
  '& .MuiSelect-select.MuiInputBase-input.MuiFilledInput-input': {
    'paddingRight': 0,
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  '& .MuiSelect-select.MuiInputBase-input': {
    'paddingRight': 0,
    'paddingBottom': 0,
    '&.MuiFocus': {
      backgroundColor: 'transparent'
    }
  },
  '& .MuiInputBase-root': {
    'padding': 0,
    'backgroundColor': 'transparent',
    '&.Mui-focused': {
      border: 'none',
      borderRadius: 8,
      backgroundColor: 'transparent'
    },
    '&.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-formControl': {
      'borderRadius': 8,
      'paddingRight': 0,
      'cursor': 'pointer',
      'display': 'flex',
      'alignItems': 'flex-end',
      '&::before': {
        border: 'transparent 1px solid',
        borderRadius: 8
      },
      '&::after': {
        border: '1px solid transparent',
        borderRadius: 8
      }
    }
  },
  '& .MuiInputLabel-root': {
    'paddingLeft': 0,
    '&.Mui-focused': {
      color: `${colors.workkiDarkMiddle} !important`
    },
    '&.Mui-error': {
      color: `${colors.workkiDarkMiddle} !important`
    }
  }
});

const StyledMenuItem = styled(MenuItem)({
  'fontWeight': 500,
  '&:hover': {
    // backgroundColor: colors.workkiLightLight2
  }
});

const StyledSelectArrow = styled(SelectArrow)({
  'transition': 'transform 0.3s ease',
  'transform': 'rotate(0deg)',
  '&.rotate': {
    transform: 'rotate(180deg)'
  }
});

const StyledMenuPaper = styled('div')({
  maxHeight: '200px',
  border: `1px solid ${colors.workkiLightLine}`,
  borderRadius: 8,
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
});

export const CustomDropdown = ({
  id,
  label,
  name,
  variant,
  value,
  onFocus,
  onBlur,
  onChange,
  placeholder,
  autoFocus,
  options,
  isFocused
}) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  return (
    <div>
      <StyledFormControl fullWidth={true}>
        <StyledTextField
          select
          name={name}
          id={id}
          label={label}
          variant={variant}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          placeholder={placeholder}
          autoFocus={autoFocus}
          SelectProps={{
            open: menuOpen,
            onClose: handleMenuClose,
            onOpen: handleMenuOpen,
            MenuProps: {
              PaperProps: { component: StyledMenuPaper }
            },
            IconComponent: () => <StyledSelectArrow onClick={handleMenuClose} rotation={menuOpen ? '180deg' : '0deg'} />
          }}
        >
          {options.map(option => (
            <StyledMenuItem key={option.value} value={option.value}>
              {option.label}
            </StyledMenuItem>
          ))}
        </StyledTextField>
      </StyledFormControl>
    </div>
  );
};
