import React from 'react';
import styled from 'styled-components';

const DocumentItem = styled.li`
  margin: 0;
  cursor: pointer;
  padding: ${props => (props.padding ? props.padding : '12px 16px')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-height: fit-content;
  // min-height: ${props => (props.minHeight ? props.minHeight : '100%')};
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 12px 0px #00000014;
  grid-area: ${props => (props.gridArea ? props.gridArea : '')};
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : '')};
`;

const CardShell = ({ children, padding, minHeight, gridArea, flexDirection, ...props }) => {
  return (
    <DocumentItem flexDirection={flexDirection} gridArea={gridArea} padding={padding} minHeight={minHeight} {...props}>
      {children}
    </DocumentItem>
  );
};

export default CardShell;
