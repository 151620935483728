import React from 'react';

const SupportInformationLinks = () => {
  const links = [
    {
      name: 'FAQ',
      url: 'https://workki.co/residents/faq/',
      target: '_blank'
    },
    {
      name: 'Правила коворкинга',
      url: 'https://workki.co/docs/Terms-of-service.pdf',
      target: '_blank'
    },
    {
      name: 'Написать директору',
      url: 'mailto:director@workki.co',
      target: '_self'
    },
    {
      name: 'Договор обработки персональный данных',
      url: 'https://workki.co/docs/Personal-data.pdf',
      target: '_self'
    }
  ];

  return (
    <div className='support__links'>
      <h2 className='title title--h2 support__title'>Полезные ссылки</h2>
      <ul className='support__links-list'>
        {links.map(item => {
          const { name, url, target } = item;

          return (
            <li key={name} className='support__links-item'>
              <a href={url} className='support__links-link link' target={target}>
                {name}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default SupportInformationLinks;
