import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';
import cn from 'classnames';

class Search extends Component {
  state = {
    isOpened: false,
    windowSize: 0
  };

  updateDimensions = () => {
    this.setState({ windowSize: window.innerWidth });
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    if (this.state.windowSize === 0) {
      this.updateDimensions();
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
    window.removeEventListener('click', this.onOutsideSearchClick);
  }

  close = () => {
    this.searchInput.current.value = '';
    this.setState({ isOpened: false }, () => {
      window.removeEventListener('click', this.onOutsideSearchClick);
    });
  };

  searchInput = React.createRef();

  onResetBtnClick = evt => {
    evt.preventDefault();
    this.props.resetLetterFilter();
  };

  onCloseBtnClick = evt => {
    evt.preventDefault();

    this.props.setQuery(null);
    this.close();
  };

  onOutsideSearchClick = evt => {
    if (!findDOMNode(this).contains(evt.target) && !this.searchInput.current.value) {
      this.onCloseBtnClick(evt);
    }
  };

  componentDidUpdate(prevProps) {
    const { activeQuery } = this.props;

    if (activeQuery === prevProps.activeQuery || activeQuery === this.searchInput.current.value) {
      return;
    }

    if (!activeQuery) {
      this.close();
    }
  }

  handleChange = evt => {
    this.props.setQuery(evt.target.value);
  };

  onSearchClick = evt => {
    evt.preventDefault();

    this.setState(
      {
        isOpened: true
      },
      () => {
        window.addEventListener('click', this.onOutsideSearchClick);
        this.props.onOpen();
      }
    );
  };

  render() {
    const { isOpened } = this.state;
    const communitySearchClasses = cn({
      search: true,
      active: this.state.windowSize > 1366 ? isOpened : true
    });

    return (
      <div className={communitySearchClasses}>
        <div className='search__btn-group'>
          <button onClick={this.onSearchClick} type='button' className='search__btn search-icon btn' />
          <button onClick={this.onResetBtnClick} type='button' className='search__reset-btn btn' />
        </div>
        <div className='search__wrap-field'>
          <span className='search__icon-field search-icon' />
          <input
            ref={this.searchInput}
            onChange={this.handleChange}
            className='search__field'
            type='text'
            name='search-field'
            placeholder='Введите запрос...'
          />
          <button type='button' onClick={this.onCloseBtnClick} className='btn search__close-btn' />
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  setQuery: PropTypes.func.isRequired,
  onOpen: PropTypes.func
};

Search.defaultProps = {
  onOpen: () => {}
};

export default Search;
