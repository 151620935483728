import PictureSlider from '../../../shared/ui/components/Booking/PictureSlider';
import CustomList from '../../../shared/ui/components/Booking/CustomList';
import { FormControl } from '@mui/material';
import { StepButton } from '../../../shared/ui/components/StepButton';
import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import fix1 from '../../../shared/utils/BackgroundProvider/assets/openSpace/fix-1.jpg';
import fix2 from '../../../shared/utils/BackgroundProvider/assets/openSpace/fix-2.jpg';
import fix3 from '../../../shared/utils/BackgroundProvider/assets/openSpace/fix-3.jpg';
import fix4 from '../../../shared/utils/BackgroundProvider/assets/openSpace/fix-4.jpg';
import fix5 from '../../../shared/utils/BackgroundProvider/assets/openSpace/fix-4.jpg';

import cab1 from '../../../shared/utils/BackgroundProvider/assets/cabinet/cab-1.jpg';
import cab2 from '../../../shared/utils/BackgroundProvider/assets/cabinet/cab-2.jpg';
import cab3 from '../../../shared/utils/BackgroundProvider/assets/cabinet/cab-3.jpg';
import cab4 from '../../../shared/utils/BackgroundProvider/assets/cabinet/cab-4.jpg';
import cab5 from '../../../shared/utils/BackgroundProvider/assets/cabinet/cab-5.jpg';
import cab6 from '../../../shared/utils/BackgroundProvider/assets/cabinet/cab-6.jpg';
import cab7 from '../../../shared/utils/BackgroundProvider/assets/cabinet/cab-7.jpg';
import cab8 from '../../../shared/utils/BackgroundProvider/assets/cabinet/cab-8.jpg';
import cab9 from '../../../shared/utils/BackgroundProvider/assets/cabinet/cab-9.jpg';

import nefix1 from '../../../shared/utils/BackgroundProvider/assets/unfixedWorkplace/nefix-1.jpg';
import nefix2 from '../../../shared/utils/BackgroundProvider/assets/unfixedWorkplace/nefix-2.jpg';
import nefix3 from '../../../shared/utils/BackgroundProvider/assets/unfixedWorkplace/nefix-3.jpg';
import nefix4 from '../../../shared/utils/BackgroundProvider/assets/unfixedWorkplace/nefix-4.jpg';
import nefix5 from '../../../shared/utils/BackgroundProvider/assets/unfixedWorkplace/nefix-5.jpg';
import { colors } from '../../../shared/ui/constants/styleConstants';
import { removeZeros } from '../../../shared/utils/serviceUtils/validationUtils';
// import { getUnfixedWorkplaceTypes } from '../../../services/workki/ServicesActions';
import withBookingWidget from '../../../shared/utils/hocs/withBookingWidget';

const TarriffsTitle = styled.p`
  margin: 16px 0 8px 0;
  font-size: 16px;
  font-weight: 500;
  font-family: Commissioner, sans-serif;
`;
const TarriffsWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
const TarriffBlock = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-right: 32px;
  & > p {
    font-family: Commissioner, sans-serif;
    margin: 0;
    font-size: 14px;
  }
  :first-child {
    font-weight: 400;
    color: ${colors.workkiDarkSecondary};
  }
  :nth-child(2) {
    font-weight: 500;
    color: ${colors.workkiDarkMain};
  }
`;
const WorkplaceInfo = ({ dialogData, handleSubmit, unfixedWorkplaces, getUnfixedWorkplaceTypes }) => {
  const [fullPrice, setFullPrice] = useState(0);
  const [localImages, setLocalImages] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [availableUnfixedWorkplaces, setAvailableUnfixedWorkplaces] = useState([]);
  const [unfixedWorkplacesData, setUnfixedWorkplacesData] = useState([]);
  const displayActivePrice = pricesInfo => {
    const activePriceInfo = pricesInfo.find(price => price.is_active === 1);
    return activePriceInfo.price;
  };
  useEffect(() => {
    const { name } = dialogData.tariff;
    if (name.includes('Кабинет')) {
      setLocalImages([cab1, cab2, cab3, cab4, cab5, cab6, cab7, cab8, cab9]);
    } else if (name.includes('Коворкинг')) {
      setLocalImages([fix1, fix2, fix3, fix4, fix5]);
    } else if (name.includes('Клуб')) {
      setLocalImages([nefix1, nefix2, nefix3, nefix4, nefix5]);
    }
    if (dialogData.is_unfixed === 0 && dialogData) {
      setFullPrice(displayActivePrice(dialogData.servicePrices));
    } else if (dialogData.is_unfixed === 1 && dialogData) {
      //TODO Исправить дублирование с виджетом бронирования
      getUnfixedWorkplaceTypes(dialogData.office_id)
        .then(response => {
          const typeInstances = response.data;
          const filteredWorkplaces = unfixedWorkplaces.reduce((acc, workplace) => {
            typeInstances.forEach(typeInstance => {
              if (workplace.id === typeInstance.id) {
                acc.push({ ...workplace, type: typeInstance.type });
              }
            });
            return acc;
          }, []);
          setAvailableUnfixedWorkplaces(filteredWorkplaces);
        })
        .catch(error => {});
    }
  }, []);
  //Подготавливаем значения для табов (Для нефиксированных рабочих мест)
  const defineActualPrice = workplace => {
    return workplace.servicePrices.find(price => price.is_active === 1);
  };
  useEffect(() => {
    if (dialogData && dialogData.is_unfixed === 1) {
      const unfixedWorkplacesTabs = availableUnfixedWorkplaces.reduce((acc, workplace) => {
        const activePriceData = defineActualPrice(workplace);
        let tariffLabel = '';
        if (workplace.name.includes('(день)')) {
          tariffLabel = 'День';
        } else if (workplace.name.includes('(тестовый день)')) {
          tariffLabel = 'Тестовый день';
        } else {
          tariffLabel = 'Месяц';
        }
        acc.push({ label: tariffLabel, subtitle: removeZeros(+activePriceData.price) + ' ₽', id: workplace.id });
        return acc;
      }, []);
      setUnfixedWorkplacesData(unfixedWorkplacesTabs);
    }
  }, [availableUnfixedWorkplaces]);
  const handleSlideChange = slideIndex => {
    setActiveSlide(slideIndex);
  };
  return (
    <>
      <PictureSlider
        images={localImages}
        useManualControls={false}
        handleSlideChange={handleSlideChange}
        galleryIndex={activeSlide}
      />
      <TarriffsTitle>Доступные тарифы</TarriffsTitle>
      {dialogData.is_unfixed === 0 ? (
        <TarriffsWrapper>
          {dialogData.servicePrices
            .filter(servicePrice => servicePrice.is_active !== 0)
            .map(servicePrice => (
              <TarriffBlock>
                <p>Месяц</p>
                <p>{servicePrice.price}₽</p>
              </TarriffBlock>
            ))}
        </TarriffsWrapper>
      ) : (
        <TarriffsWrapper>
          {unfixedWorkplacesData.map(servicePrice => (
            <TarriffBlock key={servicePrice.id}>
              <p>{servicePrice.label}</p>
              <p>{servicePrice.subtitle}</p>
            </TarriffBlock>
          ))}
        </TarriffsWrapper>
      )}
      {dialogData.tariffInfo && (
        <CustomList
          propItems={dialogData}
          label={'В тариф входит:'}
          tariffData={dialogData.tariffInfo}
          type='workplace'
        />
      )}
      <FormControl fullWidth={true}>
        <StepButton
          filledType={'red'}
          type={'filled'}
          iconColor={'white'}
          title={'Забронировать'}
          iconType={'arrow'}
          handleAction={() => handleSubmit()}
        />
      </FormControl>
    </>
  );
};
export default withBookingWidget(WorkplaceInfo);
