import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { isEmpty, last } from 'lodash';
import getCustomerPublicFilePathByFileId from '../../shared/utils/serviceUtils/getCustomerPublicFilePathByFileId';

const trimUrlProtocolAnd3W = str => {
  return str.replace(/^https?:\/\/(?:www.)?/, '');
  // .replace(/:/g, '')
  // .replace(/script/g, '');
};

const trimDangerous = str => {
  return str.replace(/script/g, '');
};

const trimCardDescription = (str, limit = 280) => {
  let sliced = str.slice(0, limit);

  if (sliced.length < str.length) {
    sliced += '...';
  }

  return sliced;
};

const ProfileAvatar = ({ profile }) => {
  const { publicFiles } = profile;

  if (!profile || !publicFiles || isEmpty(publicFiles)) {
    return <div className='profile-avatar profile-avatar--empty' />;
  }

  const avatarFile = last(publicFiles);
  const src = getCustomerPublicFilePathByFileId(avatarFile.id);
  const alt = profile.name;

  return (
    <div className='profile-avatar'>
      <img className='profile-avatar__img' src={src} alt={alt} width='84' height='84' />
    </div>
  );
};

const CommunityCard = ({ profile, single }) => (
  <div className={cn('community__card', { 'community__card--single': single })}>
    <div className='community__card-avatar'>
      <ProfileAvatar profile={profile} />
    </div>
    <div className='community__card-information'>
      <div className='community__content-column-type'>
        <div>
          <ProfileAvatar profile={profile} />
          <div>
            <div className='community__card-title'>{profile.name}</div>
            {profile.url && (
              <a
                href={trimDangerous(profile.url)}
                target='_blank'
                rel='noreferrer noopener'
                className='community__card-link link'
              >
                {trimUrlProtocolAnd3W(profile.url)}
              </a>
            )}
          </div>
        </div>
        {profile.description && (
          <div className='community__card-description'>
            <p>{trimCardDescription(profile.description)}</p>
          </div>
        )}
      </div>
    </div>
  </div>
);

CommunityCard.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    publicFiles: PropTypes.array.isRequired
  }).isRequired,
  single: PropTypes.bool
};

CommunityCard.defaultProps = {
  profile: {
    name: '',
    url: '',
    description: '',
    publicFiles: []
  },
  single: false
};

export default CommunityCard;
