import React from 'react';
import PrimaryLayout from '../layouts/PrimaryLayout';
import Community from '../components/Community/Community';

const CommunityPage = () => {
  return (
    <PrimaryLayout>
      <main className='main'>
        <Community />
      </main>
    </PrimaryLayout>
  );
};

export default CommunityPage;
