/* eslint-disable prettier/prettier */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { compose } from 'redux';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { ToastContainer } from 'react-toastify-redux';
import store from './store';
import RegistrationPage from './deprecated/pages/RegistrationPage';
import AuthorizationPage from './pages/AuthorizationPage';
import RoomReservationPage from './pages/Reservation/RoomReservationPage';
import FillPaymentPage from './deprecated/pages/FillPaymentPage';
import TransactionsPage from './pages/TransactionsPage';
import WorkplacesPage from './pages/Reservation/WorkplacesPage';
import SupportPage from './pages/SupportPage';
import NotFoundErrorPage from './pages/NotFoundErrorPage';
import DashboardPage from './pages/DashboardPage';
import ProfilePage from './pages/ProfilePage';
import PrintPage from './pages/PrintPage';
import ErrorPage from './pages/ErrorPage';
import InputTMCPage from './pages/InputTMCPage';
import ItServicesPage from './pages/ItServicesPage';
import TicketsPage from './pages/TicketsPage';
import WithPreloadData from './hocs/WithPreloadData';
import PaymentProcessErrorPage from './pages/PaymentProcessErrorPage';
import { roles } from './roles';
import { customerSelector } from './store/selectors/DefaultSelectors';
import useErrorBoundary from './hocs/ErrorBoundary';
import logger from './utils/logger';
import CommunityPage from './pages/CommunityPage';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { ruRU } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import { BackgroundProvider } from './shared/utils/BackgroundProvider/BackgroundProvider';
import ReservationParent from './pages/Reservation/ReservationParent';
import PaymentsPage from './entities/Payments/model/PaymentsPage';
import ServicesPage from './pages/ServicesPage';
import PassesPage from './pages/PassesPage';
import { EmployeesPage } from './pages/EmployeesPage';
import PrefillProfilePage from './entities/Profile/PrefillProfile/model/PrefillProfileComponent';

const redirectLog = logger('redirect');

const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/authorization/login',
  authenticatedSelector: state => {
    if (!state.isAuthenticated) {
      redirectLog('User is not authenticated, redirect to %o', '/authorization/login');
      return false;
    }
    return true;
  },
  wrapperDisplayName: 'UserIsAuthenticated'
});

const locationHelper = locationHelperBuilder({});

const userIsNotAuthenticated = connectedRouterRedirect({
  redirectPath: (state, ownProps) => locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  authenticatedSelector: state => {
    if (state.isAuthenticated) {
      redirectLog('User is authenticated, redirect to %o', '/');
      return false;
    }
    return true;
  },
  wrapperDisplayName: 'UserIsNotAuthenticated'
});

const redirectIfUserHasRole = (incomingRole, redirectPath) =>
  connectedRouterRedirect({
    redirectPath,
    authenticatedSelector: state => {
      const customer = customerSelector(state);
      const currentRole = customer && customer.role;

      if (currentRole === incomingRole) {
        redirectLog('User role: %o, redirect to %o', currentRole, redirectPath);
        return false;
      }
      return true;
    },
    wrapperDisplayName: 'RedirectIfUserHasRole'
  });

const AppRoot = () => (
  <LocalizationProvider
    dateAdapter={AdapterDayjs}
    localeText={ruRU.components.MuiLocalizationProvider.defaultProps.localeText}
    adapterLocale='ru'
  >
    <BackgroundProvider>
      <Provider store={store}>
        <>
          <Router>
            <Switch>
              <Route
                exact
                path='/'
                component={compose(
                  userIsAuthenticated,
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(DashboardPage)}
              />
              <Route
                exact
                path='/reservation'
                component={compose(
                  userIsAuthenticated,
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(ReservationParent)}
              />
              <Route
                exact
                path='/services'
                component={compose(
                  userIsAuthenticated,
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(ServicesPage)}
              />
              <Route
                exact
                path='/room-reservation'
                component={compose(
                  userIsAuthenticated,
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(RoomReservationPage)}
              />
              <Route
                exact
                path='/fill-payment'
                component={compose(
                  userIsAuthenticated,
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(FillPaymentPage)}
              />
              <Route
                exact
                path='/transactions'
                component={compose(
                  userIsAuthenticated,
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(TransactionsPage)}
              />
              <Route
                exact
                path='/payments'
                component={compose(
                  userIsAuthenticated,
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(PaymentsPage)}
              />
              <Route
                exact
                path='/employees'
                component={compose(
                  userIsAuthenticated,
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(EmployeesPage)}
              />
              <Route
                path='/prefill-profile'
                component={compose(
                  userIsAuthenticated,
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(PrefillProfilePage)}
              />
              <Route
                exact
                path='/workplaces'
                component={compose(
                  userIsAuthenticated,
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(WorkplacesPage)}
              />
              <Route
                exact
                path='/support'
                component={compose(
                  userIsAuthenticated,
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(SupportPage)}
              />
              <Route
                exact
                path='/passes'
                component={compose(
                  userIsAuthenticated,
                  redirectIfUserHasRole(roles.guest, '/prefill-profile/individual-entity'),
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(PassesPage)}
              />
              <Route
                exact
                path='/profile'
                component={compose(
                  userIsAuthenticated,
                  redirectIfUserHasRole(roles.guest, '/prefill-profile/individual-entity'),
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(ProfilePage)}
              />
              <Route
                exact
                path='/print'
                component={compose(
                  userIsAuthenticated,
                  redirectIfUserHasRole(roles.guest, '/prefill-profile/individual-entity'),
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(PrintPage)}
              />
              <Route
                exact
                path='/input-tmc'
                parkingType='car_entry'
                component={compose(
                  userIsAuthenticated,
                  redirectIfUserHasRole(roles.guest, '/prefill-profile/individual-entity'),
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(InputTMCPage)}
              />
              <Route
                exact
                path='/it'
                component={compose(
                  userIsAuthenticated,
                  redirectIfUserHasRole(roles.guest, '/prefill-profile/individual-entity'),
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(ItServicesPage)}
              />
              <Route
                exact
                path='/community'
                component={compose(
                  userIsAuthenticated,
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(CommunityPage)}
              />
              <Route
                exact
                path='/tickets'
                component={compose(
                  userIsAuthenticated,
                  WithPreloadData,
                  useErrorBoundary(ErrorPage)
                )(TicketsPage)}
              />
              <Route exact path='/deposit/process/error' component={PaymentProcessErrorPage} />
              {/*<Route exact path='/registration' component={RegistrationPage} />*/}
              {/*<Route exact path='/registration/confirm-email/:key' component={RegistrationEmailConfirmationPage} />*/}

          {/*/!* Partner program is ready but customer (the workki company) asked disable it *!/*/}
          <Route path='/authorization' component={userIsNotAuthenticated(AuthorizationPage)} />
          {/*<Route exact path='/password/reset/:token' component={ResetPasswordPage} />*/}
          <Route component={NotFoundErrorPage} />
        </Switch>
      </Router>
      <ToastContainer />
    </>
  </Provider>
    </BackgroundProvider>
  </LocalizationProvider>
);

export default AppRoot;
