import React, { useEffect, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import cn from 'classnames';
import { CustomSelectField } from '../../../shared/ui/components/Select';
import CustomDatePicker from '../../../shared/ui/components/CustomDatePicker';
import FormControl from '@mui/material/FormControl';
import { StepButton } from '../../../shared/ui/components/StepButton';
import { CustomTextField } from '../../../shared/ui/components/Field/CustomTextField';
import { styled } from 'styled-components';
import { colors } from '../../../shared/ui/constants/styleConstants';
import CustomTimePicker from '../../../shared/ui/components/CustomTimePicker';
import { InfoAlert } from '../../../shared/ui/components/Profile/InfoAlert';
import { Warning } from '../../../shared/ui/icons/core/Warning';

const FieldsGroupLabel = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 12px;
  margin-top: 30px;

  & > first-of-type {
    margin-top: 40px;
  }
`;
const TimeUnits = styled.div`
  display: flex;
  width: 100%;
  & > div {
    width: 50%;
  }
  flex-direction: row;
  & > :first-child {
    margin-right: 16px;
  }
`;
const CommonError = styled.div`
  background-color: ${colors.workkiRedLight};
  color: ${colors.workkiRedFill};
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  margin: 8px 0;
  padding: 4px;
  align-items: center;
  font-weight: 400;
  :nth-child(2) {
    margin-top: -2px;
    font-weight: 300;
  }
`;

const ParkingWidget = ({ parkingProps, scrollPosition }) => {
  const { locations, addParkingNeoGeo, addParkingParkKultury, loadingError } = parkingProps;
  const CORPUSES = [
    { label: 'A', value: '3695397004000' },
    { label: 'B', value: '3695397005000' },
    { label: 'C', value: '3695397006000' }
  ];
  const NEO_GEO_TYPE_CAR_ENTRY = '3590481191000';
  const NEO_GEO_REQUEST_TYPE = '393629546000';
  const PARK_KULTURY_TYPE_CAR_ENTRY = '4590481191001';
  const PARK_KULTURY_REQUEST_TYPE = '493629546001';
  const NEO_GEO_SERVICE_ID = 312;
  const PARK_KULTURY_SERVICE_ID = 723;
  const PARKING_TYPES = [
    { label: 'Гостевая', value: '3588462098000' },
    { label: 'Курьерская', value: '3739606434000' }
  ];

  const [carData, setCarData] = useState({
    personalInfo: '',
    carNumber: '',
    cardBrand: '',
    location: null,
    building: CORPUSES[0].value,
    parkingType: PARKING_TYPES[0].value,
    pickedDate: dayjs(),
    pickedTime: dayjs()
      .set('minute', Math.ceil(dayjs().minute() / 5) * 5)
      .startOf('minute')
  });
  const [filled, setFilled] = useState({
    parkingType: false,
    personalInfo: false,
    carNumber: false,
    cardBrand: false
  });
  const [isFocused, setIsFocused] = useState({
    parkingType: false,
    personalInfo: false,
    carNumber: false,
    cardBrand: false
  });

  const [filteredLocations, setFilteredLocations] = useState([]);
  const [selectOptions, setSelectOptions] = useState([]);

  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useEffect(() => {
    const officeNames = locations && locations.map(office => office.label);
    if (locations && locations.length > 0) {
      const filteredObjects = locations.filter(obj => officeNames.includes(obj.label));
      setFilteredLocations(filteredObjects);
      //Временная фильтрация
      const options = filteredObjects
        .filter(office => office.value.office.id !== 4 && office.value.office.id !== 7)
        .map(item => ({ value: item.value.office.id, label: item.label }));
      setSelectOptions(options);
      if (options.length > 0 && carData.location === null) {
        setCarData(prevState => ({ ...prevState, location: options[0].value }));
      }
    }
  }, [locations]);

  const setLocation = event => {
    setCarData(prevState => ({ ...prevState, location: event.target.value }));
  };
  const setBuilding = event => {
    setCarData(prevState => ({ ...prevState, building: event.target.value }));
  };
  const setParkingType = event => {
    setCarData(prevState => ({ ...prevState, parkingType: event.target.value }));
  };
  const handleInputChange = (name, inputValue) => {
    setFilled(prevFilled => ({
      ...prevFilled,
      [name]: inputValue !== ''
    }));
    setCarData(prevState => {
      return {
        ...prevState,
        [name]: inputValue
      };
    });
  };

  const handleSubmit = () => {
    const dateTime = carData.pickedDate
      .set('hour', carData.pickedTime.hour())
      .set('minute', carData.pickedTime.minute())
      .set('second', 0);
    const parking = {
      service_id: carData.location === 2 ? NEO_GEO_SERVICE_ID : PARK_KULTURY_SERVICE_ID,
      date: dateTime.format('YYYY-MM-DD HH:mm:ss'),
      name: carData.personalInfo,
      comment: '',
      car_brand: carData.cardBrand,
      car_number: carData.carNumber,
      corpus: carData.building,
      parkingType: carData.location === 2 ? NEO_GEO_TYPE_CAR_ENTRY : PARK_KULTURY_TYPE_CAR_ENTRY,
      parking: carData.parkingType,
      requestType: carData.location === 2 ? NEO_GEO_REQUEST_TYPE : PARK_KULTURY_REQUEST_TYPE,
      parking_number: ''
    };
    if (carData.location === 2) {
      addParkingNeoGeo(parking);
    } else if (carData.location === 4) {
      addParkingParkKultury(parking);
    }
  };
  const handleFocus = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: true
    }));
  };

  const handleBlur = fieldName => {
    setIsFocused(prevFocused => ({
      ...prevFocused,
      [fieldName]: false
    }));
  };
  const handleEraseValue = fieldName => {
    setCarData(prevState => ({
      ...prevState,
      [fieldName]: ''
    }));
  };
  useEffect(() => {
    const allFieldsFilled =
      carData.personalInfo &&
      carData.carNumber &&
      carData.cardBrand &&
      carData.location &&
      carData.building &&
      carData.parkingType;

    setIsSubmitDisabled(!allFieldsFilled);
  }, [carData]);

  return (
    <>
      <InfoAlert
        title={'В случае необходимости оформить пропуск для локации Парк Культуры - обратитесь к менеджеру'}
        type={'warning'}
      />
      <form
        className={cn('fill-payments-modal')}
        id='fill-payments-modal-form'
        onSubmit={handleSubmit}
        autoComplete='off'
        noValidate
      >
        <FormControl fullWidth={true}>
          <FieldsGroupLabel>
            <span>Локация</span>
          </FieldsGroupLabel>
          <CustomSelectField
            name='location'
            id='custom-select'
            label={'Локация'}
            variant='filled'
            isDisabled={selectOptions.length === 0 || selectOptions.length === 1}
            value={carData.location || ''}
            onChange={setLocation}
            options={selectOptions}
          />
          {carData.location === 2 && (
            <CustomSelectField
              name='building'
              id='custom-select'
              label={'Корпус'}
              variant='filled'
              isDisabled={false}
              value={carData.building || ''}
              onChange={setBuilding}
              options={CORPUSES}
            />
          )}
          <FieldsGroupLabel>
            <span>Прибытие</span>
          </FieldsGroupLabel>
          <TimeUnits>
            <CustomDatePicker
              type='parking'
              customLabel='Дата'
              pickedDate={carData.pickedDate}
              setPickedDate={newValue => setCarData(prevState => ({ ...prevState, pickedDate: newValue }))}
            />
            <CustomTimePicker
              scrollPosition={scrollPosition}
              pickedTime={carData.pickedTime}
              setPickedTime={newValue => setCarData(prevState => ({ ...prevState, pickedTime: newValue }))}
            />
          </TimeUnits>
          <>
            <div className='fill-payments-modal__guestRow'>
              <FieldsGroupLabel>
                <span>Общие данные</span>
              </FieldsGroupLabel>
              <CustomSelectField
                name='building'
                id='custom-select'
                label='Тип парковки'
                variant='filled'
                isDisabled={false}
                value={carData.parkingType || ''}
                onChange={setParkingType}
                options={PARKING_TYPES}
              />
              <CustomTextField
                error={''}
                type='text'
                name='personalInfo'
                id='outlined-helperText'
                label='ФИО водителя'
                variant='filled'
                value={carData.personalInfo}
                onFocus={() => handleFocus('personalInfo')}
                onBlur={() => handleBlur('personalInfo')}
                onChange={e => handleInputChange('personalInfo', e.target.value)}
                helperText={''}
                endAdornmentProps={{
                  filled: filled.personalInfo,
                  isFocused: isFocused.personalInfo,
                  handleEraseValue: name => handleEraseValue(name)
                }}
                autoFocus={false}
              />
              <CustomTextField
                error={''}
                type='text'
                name='carNumber'
                id='outlined-helperText'
                label='Номер автомобиля'
                variant='filled'
                value={carData.carNumber}
                onFocus={() => handleFocus('carNumber')}
                onBlur={() => handleBlur('carNumber')}
                onChange={e => handleInputChange('carNumber', e.target.value)}
                helperText={''}
                endAdornmentProps={{
                  filled: filled.carNumber,
                  isFocused: isFocused.carNumber,
                  handleEraseValue: name => handleEraseValue(name)
                }}
                autoFocus={false}
              />
              <CustomTextField
                error={''}
                type='text'
                name='cardBrand'
                id='outlined-helperText'
                label='Марка автомобиля'
                variant='filled'
                value={carData.cardBrand}
                onFocus={() => handleFocus('cardBrand')}
                onBlur={() => handleBlur('cardBrand')}
                onChange={e => handleInputChange('cardBrand', e.target.value)}
                helperText={''}
                endAdornmentProps={{
                  filled: filled.cardBrand,
                  isFocused: isFocused.cardBrand,
                  handleEraseValue: name => handleEraseValue(name)
                }}
                autoFocus={false}
              />
              {loadingError && (
                <CommonError>
                  <span>
                    <Warning spacing='10px' defaultColor='#DB4344' />
                  </span>
                  <span>{loadingError}</span>
                </CommonError>
              )}
            </div>
          </>
          <FormControl fullWidth style={{ marginTop: '32px' }}>
            <StepButton
              filledType='black'
              type='filled'
              title='Отправить заявку'
              handleAction={handleSubmit}
              disabled={isSubmitDisabled}
            />
          </FormControl>
        </FormControl>
      </form>
    </>
  );
};

export default ParkingWidget;
