import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import React from 'react';
import { colors } from '../../ui/constants/styleConstants';

const StyledSwitch = styled(Switch)(({ size, isChecked, spacing }) => ({
  'marginRight': spacing ? spacing : 0,
  'backgroundColor': isChecked ? colors.workkiDarkFill : colors.workkiLightLine,
  'boxShadow': '0px 1px 3px 0px #00000026 inset',
  'padding': '0',
  'width': size === 'small' ? '28px' : '44px',
  'height': size === 'small' ? '16px' : '24px',
  'borderRadius': '12px',

  '& .MuiSwitch-switchBase': {
    'padding': size === 'small' ? '3px' : '4px',
    '&.Mui-checked': {
      transform: size === 'small' ? 'translateX(12px)' : 'translateX(20px)'
    }
  },

  '& .MuiSwitch-thumb': {
    backgroundColor: '#fff',
    width: size === 'small' ? '10px' : '16px',
    height: size === 'small' ? '10px' : '16px',
    boxShadow: '0px 2px 12px 0px #00000014'
  },

  '& .MuiSwitch-track': {
    backgroundColor: '#fff0'
  },
  '& .MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked': {
    backgroundColor: colors.workkiDarkFill
  },

  '& .Mui-checked + .MuiSwitch-track': {
    backgroundColor: `${colors.workkiDarkFill} !important`
  },

  '& .MuiSwitch-colorSecondary.Mui-checked': {
    color: '#fff'
  }
}));

const CustomSwitch = ({ spacing, size, onChange, inputRef, required, disabled, checked, ref, ...props }) => {
  return (
    <StyledSwitch
      spacing={spacing}
      isChecked={checked}
      size={size}
      onChange={onChange}
      inputRef={inputRef}
      required={required}
      disabled={disabled}
      checked={checked}
      ref={ref}
      {...props}
    />
  );
};

export default CustomSwitch;
