import { combineReducers } from 'redux';
import { toastsReducer as toasts } from 'react-toastify-redux';
import { createReducer } from 'redux-orm';
import * as actions from '../actions';
import ui from './ui';
import orm from '../models/orm';
import location from './location';
import settings from './settings';
import isAuthenticated from './isAuthenticated';
import isModalOpen from './isModalOpen';
import errors from './errors';
import pagination from './pagination';
import requestStatuses from './requestStatuses';

const entities = createReducer(orm);

const appReducer = combineReducers({
  isAuthenticated,
  isModalOpen,
  errors,
  settings,
  entities,
  ui,
  requestStatuses,
  pagination,
  location,
  toasts
});

/**
 * Root reducer for resetting the store
 * Credits to Dan Abramov: https://stackoverflow.com/a/35641992
 **/
const rootReducer = (state, action) => {
  if (action.type === actions.logout.toString()) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
