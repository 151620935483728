import React from 'react';
import { InputAdornment } from '@mui/material';
import { IconComponent } from './IconComponent';
import { Warning } from '../../icons/core/Warning';

export const EndAdornment = ({
  filled,
  isFocused,
  fieldName,
  errors,
  onFocus,
  handleEraseValue,
  showPassword,
  handleClickShowPassword
}) => {
  return (
    <InputAdornment
      onMouseDown={e => {
        e.preventDefault();
        onFocus();
      }}
      position='end'
    >
      {filled && isFocused && (
        <IconComponent
          type='erase'
          handleAction={() => handleEraseValue(fieldName)}
          defaultColor=''
          hoverColor='#c8c9ca'
        />
      )}
      {errors && !isFocused && <Warning spacing='16px' defaultColor='#DB4344' />}
      {isFocused && (fieldName === 'password' || fieldName === 'matchPassword') && (
        <>
          {showPassword ? (
            <IconComponent
              type='show'
              spacing='16px'
              defaultColor='#24272A'
              handleAction={handleClickShowPassword}
              hoverColor='#383c40'
            />
          ) : (
            <IconComponent
              type='hide'
              defaultColor='#24272A'
              handleAction={handleClickShowPassword}
              hoverColor='#383c40'
            />
          )}
        </>
      )}
    </InputAdornment>
  );
};

export default EndAdornment;
