export const PRICE_COUNT_TYPE = {
  ONCE: 1,
  BY_MINUTES: 2,
  BY_HOURS: 3,
  BY_DAY: 4,
  BY_MONTH: 5
};

export const FILE_TYPE_ID = {
  PASSPORT: 1,
  OTHER: 52,
  PHOTO: 55,
  PUBLIC: 56
};

export const SERVICE_RUN_TYPE = {
  PRINT_BLACK: 1,
  PRINT_COLOR: 2,
  WIFI: 3,
  SCUD_NEO: 4,
  SCUD_CITY: 5,
  SCUD_NEO_GUEST: 6,
  SCUD_CITY_GUEST: 7,
  IT_OTHER: 8,
  INTERNET: 9,
  IP: 10,
  INTERNET_CHANNEL: 11,
  SCUD_PARK_KULTURY_GUEST: 12,
  SCUD_KOMSOMOLSKAYA: 13
};

export const SERVICE_TYPE = {
  MEETING_ROOM: 1,
  MULTIMEDIA_MEETING_ROOM: 2,
  EVENT_SPACE: 3,
  TARIFF: 4,
  WORK_PLACE: 5,
  PAYMENT_CONF: 6,
  IT: 7,
  SCUD: 9,
  PRINT: 10
};
