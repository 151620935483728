import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useWindowSize } from '../utils/getWindowSize';

const LocationPicker = ({ setLocation, additionalClasses, locations, currentLocation }) => {
  const onLocationBtnClick = location => evt => {
    evt.preventDefault();
    setLocation(location);
  };

  const [visibleList, setVisibleList] = useState(false);
  const [windowWidth] = useWindowSize();

  const _setVisibleList = () => {
    if (window.innerWidth < 748) setVisibleList(!visibleList);
  };

  const className = classNames('location-picker', additionalClasses);

  if (
    additionalClasses &&
    additionalClasses.length &&
    additionalClasses[additionalClasses.length - 1] === 'workplace-reservation__location-main' &&
    windowWidth < 748
  ) {
    return (
      <div
        onClick={_setVisibleList}
        className={`workplace-reservation__list-container ${visibleList && 'rotate-icon'}`}
      >
        <p className='workplace-reservation__list-label-style'>{currentLocation.t_name}</p>
        <ul className={`workplace-reservation__section-list ${visibleList && 'visible-list'}`}>
          {locations.map(item => {
            return (
              <li className='workplace-reservation__section-item' key={item.id}>
                <button
                  type='button'
                  onClick={onLocationBtnClick(item)}
                  name={item.value || item.id}
                  className={`workplace-reservation__section-btn btn btn--ghost ${currentLocation.id === item.id &&
                    'active-item'}`}
                >
                  {item.t_name}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  return (
    <div className={className}>
      <ul className='location-picker__list'>
        {locations.map(item => {
          const locationMenuItemClass = classNames({
            'location-picker__item': true,
            'active': currentLocation.id === item.id
          });

          return (
            <li className={locationMenuItemClass} key={item.id}>
              <button
                type='button'
                onClick={onLocationBtnClick(item)}
                name={item.value || item.id}
                className='location-picker__btn btn'
              >
                {item.t_name}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

LocationPicker.propTypes = {
  currentLocation: PropTypes.object.isRequired,
  locations: PropTypes.array.isRequired,
  setLocation: PropTypes.func.isRequired,
  additionalClasses: PropTypes.arrayOf(PropTypes.string)
};

export default LocationPicker;
