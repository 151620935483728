import React from 'react';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabLabel } from './TabLabel';

const StyledTabs = styled(Tabs)(({ theme, tabsContainerStyle }) => ({
  'border': '1px solid #c8c9ca',
  'padding': '4px',
  'borderRadius': '6px',
  'minHeight': tabsContainerStyle.height ? tabsContainerStyle.height : 'auto',
  'minWidth': tabsContainerStyle.width ? tabsContainerStyle.width : 'auto',
  'maxWidth': tabsContainerStyle.width ? tabsContainerStyle.width : 'auto',
  'justifyContent': 'center',
  '& .MuiTabs-flexContainer': {
    display: 'flex'
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent'
  },
  'justifySelf': 'center'
}));

const StyledTab = styled(Tab)(({ tabStyle }) => ({
  'flex': 1,
  'borderRadius': '5px',
  'height': tabStyle.height ? tabStyle.height : 'auto',
  'width': tabStyle.width ? tabStyle.width : 'auto',
  'textTransform': 'none',
  'padding': '10px 8px 12px 8px',
  'fontSize': '14px',
  'fontWeight': 550,
  'transition': 'all 0.5s cubic-bezier(0.42, 0, 0.97, 0.33)',
  'minHeight': tabStyle.height ? tabStyle.height : 'auto',
  'maxHeight': tabStyle.height ? tabStyle.height : 'auto',
  'minWidth': tabStyle.width ? tabStyle.width : 'auto',
  // 'maxWidth': tabStyle.width ? tabStyle.width : 'auto',
  '&:first-child': {
    marginRight: '4px'
  },
  '&.Mui-selected': {
    backgroundColor: '#383c40',
    color: 'white'
  },
  '& .MuiTab-wrapper': {
    margin: 0
  },
  '&:not(.Mui-selected)': {
    backgroundColor: 'transparent',
    color: '#383c40'
  }
}));

export const TabsComponent = ({ tabValue, handleTabChange, tabs, tabsContainerStyle, tabStyle }) => {
  return (
    <>
      <StyledTabs tabsContainerStyle={tabsContainerStyle} value={tabValue} onChange={handleTabChange}>
        {tabs.map((tab, index) => (
          <StyledTab
            value={tab.value}
            tabStyle={tabStyle}
            key={index}
            label={<TabLabel label={tab.label} subtitle={tab.subtitle} isActive={tabValue === index} />}
          />
        ))}
      </StyledTabs>
    </>
  );
};
