import * as actions from '../../store/actions';
import { pick, pickBy, isNil } from 'lodash';
import api from './Request';
import { roles } from '../../roles';
import { getRequestStatusActions } from '../../utils/createRequestActions';

export const getCustomerCurrent = () => {
  return api.get('/customer/current', {}, [
    actions.requestCustomerCurrentPending,
    actions.requestCustomerCurrentSuccess,
    actions.requestCustomerCurrentFailure
  ]);
};

export const getActualCustomerCurrent = () => {
  return api.get('/customer/current', {}, [
    actions.requestActualCustomerCurrentPending,
    actions.requestActualCustomerCurrentSuccess,
    actions.requestActualCustomerCurrentFailure
  ]);
};

export const getCustomerSettings = () => {
  return api.get('/customer/settings', {}, getRequestStatusActions(actions.requestCustomerSettings));
};

export const changeCustomerProfileIsPublic = isPublic => {
  return api.put(
    '/customer/settings',
    { data: { is_public: isPublic } },
    getRequestStatusActions(actions.requestChangeProfileIsPublic),
    false
  );
};

export const changeCustomerProfilePublicFields = (id, profile) => {
  const allowedValues = ['name', 'description', 'url'];
  const allowedData = pick(profile, allowedValues);
  const data = pickBy(allowedData, v => !isNil(v));
  return api.put(
    'customer/profile',
    { data },
    [
      actions.requestCustomerProfileChangePublicFieldsPending,
      response => actions.requestCustomerProfileChangePublicFieldsSuccess(response, id),
      actions.requestCustomerProfileChangePublicFieldsFailure
    ],
    false
  );
};

/**
 * Create Customer
 */

export const getCustomerCompanyData = innValue => {
  return api.post('/customer/info/company/address', { data: { inn: innValue } }, [
    actions.requestCustomerCompanyNamePending,
    actions.requestCustomerCompanyNameSuccess,
    actions.requestCustomerCompanyNameFailure
  ]);
};

export const getCustomerAddressHints = formData => {
  const data = pick(formData, ['address']);
  return api.post('/customer/info/company/address/hints', { data }, [
    actions.requestCustomerCompanyNamePending,
    actions.requestCustomerCompanyNameSuccess,
    actions.requestCustomerCompanyNameFailure
  ]);
};

export const getCustomerAddressSegmentHints = (formData, segment) => {
  const data = pick(formData, ['address']);
  const url = `/customer/info/company/address/${segment}/hints`;
  return api.post(url, { data }, [
    actions.requestCustomerCompanyNamePending,
    actions.requestCustomerCompanyNameSuccess,
    actions.requestCustomerCompanyNameFailure
  ]);
};

export const createCustomerCurrentIndividualCompany = data => {
  // const allowedFields = [
  //   'name',
  //   'name_short',
  //   'inn',
  //   'kpp',
  //   'full_address',
  //   'registered_country_id',
  //   'ogrn',
  //   'director_first_name',
  //   'director_middle_name',
  //   'director_last_name',
  //   'director_post',
  //   'director_email',
  //   'director_phone',
  //   'city',
  //   'street',
  //   'house',
  //   'index'
  // ];
  // const data = pick(formData, allowedFields);

  return api.post(
    '/customer/info/company/complete',
    { data },
    [
      actions.requestCreateCustomerCompanyPending,
      actions.requestCreateCustomerCompanySuccess,
      actions.requestCreateCustomerCompanyFailure
    ],
    false
  );
};

export const createCustomerCurrentIndividualInfo = data => {
  const headers = { 'content-type': 'application/x-www-form-urlencoded' };

  return api.post(
    '/customer/info/complete',
    { data, headers },
    [
      actions.requestCreateCustomerIndividualPending,
      actions.requestCreateCustomerIndividualSuccess,
      actions.requestCreateCustomerIndividualFailure
    ],
    false
  );
};

/**
 * Customer files
 */

export const getCustomerFiles = () => {
  return api.get('/customer/file', { params: { _disablePagination: 1 } }, [
    actions.requestCustomerProfileFilesPending,
    actions.requestCustomerProfileFilesSuccess,
    actions.requestCustomerProfileFilesFailure
  ]);
};

export const getBinaryFile = id => {
  return api.get(`/customer/file/${id}/show`, {
    responseType: 'blob',
    timeout: 60000
  });
};

export const sendCustomerFileWithTypeId = (typeId, file) => {
  const headers = { 'content-type': 'multipart/form-data' };

  const data = new FormData();
  data.append('type_id', typeId);
  data.append('file', file);

  return api.post(
    '/customer/file',
    { data, headers },
    [
      actions.requestCreateCustomerFilePending,
      actions.requestCreateCustomerFileSuccess,
      actions.requestCreateCustomerFileFailure
    ],
    false
  );
};

export const sendFileToPrintDirectly = file => {
  const headers = { 'content-type': 'multipart/form-data' };

  const data = new FormData();
  data.append('uploadedFile', file);

  return api.post(
    '/customer/file/print',
    { data, headers },
    [
      actions.requestServiceSendFileToPrinterPending,
      actions.requestServiceSendFileToPrinterSuccess,
      actions.requestServiceSendFileToPrinterFailure
    ],
    false
  );
};

export const getBalancePrinter = () => api.get('/customer/printer', { permissions: { denyTo: [roles.guest] } });

export const getCustomerContracts = () => {
  return api.get(
    '/customer/contracts',
    { params: { _disablePagination: 1 } },
    getRequestStatusActions(actions.requestCustomerContracts)
  );
};

export const getBinaryFileContract = () =>
  api.get(`/customer/contracts/download`, {
    responseType: 'blob',
    timeout: 60000
  });

export const getBinaryFileSubleaseContract = id =>
  api.get(`/customer/contracts/download-sublease-contract/${id}`, {
    responseType: 'blob',
    timeout: 60000
  });
