import React from 'react';
// import ReactTooltip from 'react-tooltip';

const PrintCurrentPackage = props => {
  const { balance, customer } = props;
  if (!customer || !customer.printer || !customer.printer.pin) {
    return null;
  }
  return (
    <div className='print__current-package print__section'>
      <div className='print__current-package-footer'>
        <div className='print__current-package-remainder'>
          Осталось:
          <span className='print__current-package-remainder-count print__current-package-title title title--h2'>
            {balance} ч/б или {Math.floor(balance / 4)} цветных
          </span>
        </div>
        <div className='print__current-package-pin'>
          Ваш пинкод для печати:
          <span className='print__current-package-pin-number'>{customer.printer.pin}</span>
          {/* <button
            data-tip="custom"
            data-event="click"
            data-for="tooltip-print"
            className="btn hint print__current-package-pin-hint"
            type="button"
          />
          <ReactTooltip
            globalEventOff="click"
            effect="solid"
            id="tooltip-print"
            className="tooltip"
          >
            <p>Здесь очень важный текст для тултипа!</p>
          </ReactTooltip> */}
        </div>
      </div>
    </div>
  );
};

export default PrintCurrentPackage;
