import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import CommunityCard from './CommunityCard';

const CommunityList = props => {
  const { communitiesToShow, activeLetter, activeQuery } = props;

  if (isEmpty(communitiesToShow)) {
    return (
      <div className='community__notification'>
        <div className='notification notification--warning'>
          Компаний, начинающихся на
          <span className='notification__text'>{`${activeLetter || activeQuery}`}</span> не найдено
        </div>
      </div>
    );
  }

  return (
    <div className='community__list'>
      {communitiesToShow.map((profile, i) => (
        <CommunityCard key={`${profile.name}_${i}`} profile={profile} />
      ))}
    </div>
  );
};

CommunityList.propTypes = {
  communitiesToShow: PropTypes.array.isRequired,
  activeLetter: PropTypes.string,
  activeQuery: PropTypes.string
};

export default CommunityList;
