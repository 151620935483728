import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { success, error } from 'react-toastify-redux';

import * as actions from '../../store/actions';
import PageLoader from '../PageLoader';
import {
  printerPackages,
  serviceRequestWithWorkplacesSelector,
  servicesSelector
} from '../../store/selectors/ServicesSelector';
import PrintProductCard from './PrintProductCard';
import { dispatchBuyNow } from '../../services/workki/ServicesActions';
import ErrorWithShowedMessage from '../../errors/ErrorWithShowedMessage';
import { getServiceSchedule } from '../../store/selectors/ServicesSelector';
import { getCustomerContracts } from '../../services/workki/CustomerActions';
import requestStatusSelector from '../../store/selectors/requestStatusSelector';
import { customerSelector, officeSelector } from '../../store/selectors/DefaultSelectors';

const mapStateToProps = state => ({
  auth: state.auth,
  all: printerPackages(state),
  serviceRequestCreateRequestStatus: requestStatusSelector(actions.requestServiceRequestCreate)(state),
  contract: customerSelector(state).contract,
  offices: officeSelector(state),
  meetingRooms: getServiceSchedule(state)
});

const mapDispatchToProps = dispatch => ({
  test: () => {
    dispatch(success('Success message'));
  },
  getContracts: () => dispatch(getCustomerContracts()),
  buyPackage: (service_id, cnt, price_id, callback, contract) => {
    dispatch(dispatchBuyNow(service_id, null, cnt, price_id, contract))
      .then(() => {
        dispatch(success('Успешная покупка печати', { hideProgressBar: true }));
      })
      .catch(err => {
        if (err instanceof ErrorWithShowedMessage) {
          throw err;
        }

        let message = 'Ошибка покупки печати!';

        if (err !== null && err.response && err.response.data) {
          message = Array.isArray(err.response.data) ? err.response.data[0].message : err.response.data.message;
        }

        dispatch(error(message, { hideProgressBar: true }));
      })
      .then(() => {
        if (typeof callback === 'function') {
          callback();
        }
      })
      .catch(() => {});
  }
});

const PrintPackages = ({
  all,
  buyPackage,
  reloadBalance,
  serviceRequestCreateRequestStatus,
  getContracts,
  contract,
  offices,
  meetingRooms
}) => {
  const activeWorkplaces = useSelector(state =>
    serviceRequestWithWorkplacesSelector(state, {
      dateCurrentSubscriptionView: moment().subtract(14, 'days'),
      selectorType: 'standard'
    })
  );

  const allServices = useSelector(servicesSelector);

  const printServicesFiltered = useMemo(() => {
    if (activeWorkplaces.length > 0 || meetingRooms.length > 0) {
      let service_ids = [...meetingRooms, ...activeWorkplaces].map(s => s.service_id);
      let office_ids = allServices.filter(s => service_ids.includes(s.id)).map(s => s.office_id);

      return all.filter(s => office_ids.includes(s.office_id));
    }

    return all.filter(s => !s.office_id);
  }, [activeWorkplaces, all, allServices]);

  useEffect(() => {
    if (!contract) {
      getContracts();
    }
  }, [contract, getContracts]);

  if (!contract) {
    return <PageLoader />;
  }

  return (
    <div className='print__packages'>
      <ul className='print__packages-list'>
        {printServicesFiltered.map(item => (
          <li key={item.id} className='print__packages-item print__section'>
            <PrintProductCard
              printPackage={item}
              buyPackage={buyPackage}
              reloadBalance={reloadBalance}
              isLoading={serviceRequestCreateRequestStatus.isPending}
              contract={contract}
              office={offices.filter(f => item.office_id == f.id)[0]}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

PrintPackages.propTypes = {
  // all: PropTypes.array.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrintPackages);
