import React from 'react';
import iconPhone from '../../../media/icons/phone.svg';
import iconMail from '../../../media/icons/mail.svg';

const SupportInformationContacts = ({ locationData, formatPhoneNumber }) => {
  return (
    <div className='support__contacts'>
      <h2 className='title title--h2 support__title'>Контакты</h2>
      <ul className='support__contacts-list'>
        <li className='support__contacts-item'>
          {locationData.officePhones.map(phone => (
            <a className='support__contacts-link link' href={`tel: ${formatPhoneNumber(phone.number)}`}>
              <span className='support__contacts-img-wrap'>
                <img
                  className='support__contacts-img'
                  src={iconPhone}
                  alt='Номер телефона'
                  width='auto'
                  height='auto'
                />
              </span>
              <span className='support__contacts-text'>{formatPhoneNumber(phone.number)}</span>
            </a>
          ))}
        </li>
        <li className='support__contacts-item'>
          <a className='support__contacts-link link' href={`mailto: ${locationData.email}`}>
            <span className='support__contacts-img-wrap'>
              <img className='support__contacts-img' src={iconMail} alt='Почтовый адрес' width='auto' height='auto' />
            </span>
            <span className='support__contacts-text'> {locationData.email} </span>
          </a>
        </li>
        <li className='support__contacts-item'>
          <a className='support__contacts-link link' href={`tel: + 7 (499) 490-48-15`}>
            <span className='support__contacts-img-wrap'>
              <img className='support__contacts-img' src={iconPhone} alt='Номер телефона' width='auto' height='auto' />
            </span>
            <span className='support__contacts-text'>+ 7 (499) 490-48-15 (ИТ)</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SupportInformationContacts;
